"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _config = require("../../typography/config");

var _helpers = require("../helpers");

var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var style = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: ", " solid var(--bob-core-components-color-violet);\n  background-color: white;\n  height: ", ";\n  width: ", ";\n  margin: ", ";\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.variables.sizes.twoPx;
}, function (_ref2) {
  var size = _ref2.size;
  return (0, _helpers.getBtnSizeByText)(size);
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.variables.sizes.button.senary.width;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.variables.sizes.padding;
});
var dark = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var light = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n"])), style, _config.variables['bob-core-components-typography__bold--medium--violet']);
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;