"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var light = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: var(--bob-core-components-color-violet);\n  box-shadow: 0 0.1875rem 0.375rem var(--bob-core-components-color-ash);\n  &:hover,\n  &:active,\n  &:focus {\n    background-color: var(--bob-core-components-color-violet80);\n    box-shadow: 0 0.1875rem 0.375rem var(--bob-core-components-color-violet);\n  }\n"])));
var dark = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var _default = {
  light: light,
  dark: dark
};
exports.default = _default;