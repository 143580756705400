"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnimationContainer = exports.SelectListContainer = exports.Container = exports.Card = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _framerMotion = require("../framerMotion");

var _Card = _interopRequireDefault(require("../Card"));

var _card = require("../card/card.styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Card = (0, _styledComponents.default)(_Card.default)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    padding: 0;\n  }  overflow: visible;\n  min-height: 0;\n  > * {\n    &:first-child {\n      > li {\n        overflow: hidden;\n        &:first-child {\n          border-top-right-radius: ", ";\n          border-top-left-radius: ", ";\n        }\n        &:last-child {\n          border-bottom-right-radius: ", ";\n          border-bottom-left-radius: ", ";\n        }\n      }\n    }\n  }\n"], ["\n  ", " {\n    padding: 0;\n  }  overflow: visible;\n  min-height: 0;\n  > * {\n    &:first-child {\n      > li {\n        overflow: hidden;\n        &:first-child {\n          border-top-right-radius: ", ";\n          border-top-left-radius: ", ";\n        }\n        &:last-child {\n          border-bottom-right-radius: ", ";\n          border-bottom-left-radius: ", ";\n        }\n      }\n    }\n  }\n"])), _card.ScrollableContent, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
});
exports.Card = Card;

var Container = _styledComponents.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));

exports.Container = Container;
var SelectListContainer = (0, _styledComponents.default)(_framerMotion.motion.div).attrs(function (_a) {
  var open = _a.open;
  return {// QuickFix #1487: Disable animations for now. Text is distorted.
    // layout: true,
    // initial: { height: open ? "auto" : 0 },
    // initial: { height: "auto"},
    // animate: false,
    // exit: { height: 0 }
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: ", ";\n  font-size: ", "; // as input\n  ", ";\n"], ["\n  margin-top: ", ";\n  font-size: ", "; // as input\n  ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.twoPx;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fontSizes.medium;
}, function (_a) {
  var disabled = _a.disabled;
  return disabled && 'pointer-events: none';
});
exports.SelectListContainer = SelectListContainer;
var AnimationContainer = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
exports.AnimationContainer = AnimationContainer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;