"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _helpers = require("../helpers");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var styles = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  outline: none;\n  height: ", ";\n  padding: ", ";\n  border-radius: ", ";\n"])), function (_ref) {
  var size = _ref.size;
  return (0, _helpers.getBtnSizeByText)(size);
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.variables.sizes.padding;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.variables.sizes.button.primary.borderRadius;
});
var fadeIn = (0, _styledComponents.keyframes)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  0% {\n    background-color: var(--bob-core-components-color-grain);\n  }\n  50% {\n    background-color: transparent;\n  }\n  100% {\n    background-color: var(--bob-core-components-color-grain);\n  }\n"])));
var dark = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
var light = (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  background-color: var(--bob-core-components-color-grain);\n  border: ", " solid var(--bob-core-components-color-grain);\n  &:hover {\n    border: ", " solid var(--bob-core-components-color-grain60);\n    background-color: var(--bob-core-components-color-grain60);\n  }\n  &:focus {\n    border: ", " solid var(--bob-core-components-color-grain60);\n    background-color: var(--bob-core-components-color-grain60);\n    box-shadow: 0 0 ", " var(--bob-core-components-color-violet40);\n  }\n  &:active {\n    background-color: var(--bob-core-components-color-grain);\n    box-shadow: inset 0 ", " ", "\n      var(--bob-core-components-color-grain-darker);\n  }\n  &:disabled {\n    border: ", " solid var(--bob-core-components-color-grain40);\n    background-color: var(--bob-core-components-color-grain40);\n    color: var(--bob-core-components-color-eggplant40);\n    cursor: default;\n    pointer-events: none;\n  }\n"])), styles, function (_ref4) {
  var loading = _ref4.loading;
  return loading && (0, _styledComponents.css)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      animation: 2s ", " ease-in-out infinite;\n    "])), fadeIn);
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.variables.sizes.twoPx;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.variables.sizes.twoPx;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.variables.sizes.twoPx;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.variables.sizes.padding;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.variables.sizes.twoPx;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.variables.sizes.padding;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.variables.sizes.twoPx;
});
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;