"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Line = exports.InnerContainer = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = _styledComponents.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  display: flex;\n  align-items: center;\n"], ["\n  height: ", ";\n  display: flex;\n  align-items: center;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.slider.height;
});

exports.Container = Container;

var InnerContainer = _styledComponents.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: ", ";\n  background-color: ", ";\n  //background-color: var(--bob-core-components-color-light-grey-2);\n  border-radius: ", ";\n  cursor: pointer;\n"], ["\n  position: relative;\n  width: 100%;\n  height: ", ";\n  background-color: ", ";\n  //background-color: var(--bob-core-components-color-light-grey-2);\n  border-radius: ", ";\n  cursor: pointer;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors["light-grey-2"];
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
});

exports.InnerContainer = InnerContainer;

var Line = _styledComponents.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  border-radius: ", ";\n  cursor: pointer;\n  height: ", ";\n  max-width: 100%;\n\n  background-color: ", ";\n  //background-color: var(--bob-core-components-color-violet);\n"], ["\n  position: absolute;\n  border-radius: ", ";\n  cursor: pointer;\n  height: ", ";\n  max-width: 100%;\n\n  background-color: ", ";\n  //background-color: var(--bob-core-components-color-violet);\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors["violet"];
});

exports.Line = Line;
var templateObject_1, templateObject_2, templateObject_3;