export default {
  'nb-NO': {
    'P-rom': 'P-rom',
    'Opprinnelig antall rom': 'Opprinnelig antall rom',
    Visning: 'Visning',
    Visningsholder: 'Visningsholder',
    Tlf: 'Tlf',
    Ansiennitetsregler: 'Ansiennitetsregler',
    'Kontakt saksbehandler': 'Kontakt saksbehandler',
    Meglerforetak: 'Meglerforetak',
    Annet: 'Annet',
    'Overtagelsesdato er': 'Overtagelsesdato er',
  },
};
