"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var styles = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  outline: none;\n"])));
var dark = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var light = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  background-color: transparent;\n  &:hover {\n    svg {\n      opacity: 0.8;\n    }\n  }\n  &:focus {\n    svg {\n      opacity: 1;\n    }\n  }\n  &:active {\n    svg {\n      opacity: 0.5;\n    }\n  }\n  &:disabled {\n    svg {\n      opacity: 0.3;\n    }\n    cursor: default;\n    pointer-events: none;\n  }\n"])), styles);
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;