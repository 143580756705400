"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Text = exports.Title = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = _styledComponents.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 300px;\n  margin: 2rem auto;\n  padding: 0 1rem;\n\n  svg {\n    width: 5rem;\n    height: 5rem;\n  }\n"], ["\n  max-width: 300px;\n  margin: 2rem auto;\n  padding: 0 1rem;\n\n  svg {\n    width: 5rem;\n    height: 5rem;\n  }\n"])));

exports.Container = Container;

var Title = _styledComponents.default.h2.attrs(function () {
  return {
    className: 'bob-core-components-typography__bold--large--violet'
  };
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));

exports.Title = Title;

var Text = _styledComponents.default.p.attrs(function () {
  return {
    className: 'bob-core-components-typography__regular--medium--coal'
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));

exports.Text = Text;
var templateObject_1, templateObject_2, templateObject_3;