"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Bar", {
  enumerable: true,
  get: function get() {
    return _bar.default;
  }
});
exports.Line = exports.Labels = exports.Label = exports.Bars = exports.Container = exports.sizes = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _bar = _interopRequireDefault(require("./bar/bar.styles"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var sizes = {
  small: '10',
  medium: '20',
  large: '30'
};
exports.sizes = sizes;

var Container = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  padding-top: 3rem; //popover height\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: ", "rem;\n  > div {\n    transition: all 250ms cubic-bezier(0.78, 0.02, 0.58, 1);\n  }\n"])), function (_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? sizes.small : _ref$size;
  return size && sizes[size] || sizes.small;
});

exports.Container = Container;

var Bars = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: baseline;\n  height: 100%;\n  width: calc(100% - 1rem);\n  justify-content: space-between;\n  flex-grow: 1;\n"])));

exports.Bars = Bars;

var Label = _styledComponents.default.label.attrs(function () {
  return {
    className: 'bob-core-components-typography__regular--x-small--coal'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: 1rem;\n  min-height: 0.5rem;\n  overflow: visible;\n"])));

exports.Label = Label;
var Labels = (0, _styledComponents.default)(Bars)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
exports.Labels = Labels;

var Line = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 0.125rem;\n  min-height: 0.125rem;\n  max-height: 0.125rem;\n  background-color: var(\n    --bob-core-components-color-bar-diagram-line-background\n  );\n  box-shadow: 0 0.1875rem 0.375rem var(--bob-core-components-color-ash);\n"])));

exports.Line = Line;