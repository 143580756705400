
export default {
  'nb-NO': {
    'E-post / Mobil': 'E-post / Mobil',
    Passord: 'Passord',
    'Logg inn': 'Logg inn',
    'Lag nytt passord': 'Lag nytt passord',
    Fødselsdato: 'Fødselsdato',
    Mobilnummer: 'Mobilnummer',
    Medlemskap: 'Medlemskap',
  },
};
