"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _constants = require("../constants");

var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var style = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  ", " {\n    margin: 0;\n  }\n\n  &", " {\n    box-shadow: inset 0 -", " 0 0 var(--bob-core-components-color-grain);\n  }\n"])), ".".concat(_constants.NAME, "__textspan"), ".".concat(_constants.NAME, "--selected"), function (_ref) {
  var theme = _ref.theme;
  return theme.variables.sizes.fourthOfPadding;
});
var dark = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var light = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  background-color: transparent;\n  outline: none;\n"])), style);
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;