"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Component = exports.MotionComponent = exports.styles = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _framerMotion = require("framer-motion");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var styles = (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  padding: 0;\n  top: ", ";\n  width: ", ";\n  height: ", ";\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n  border-radius: 50%;\n  box-shadow: 0 0 ", "\n    ", ";\n  outline: none;\n  cursor: pointer;\n  border: ", " solid\n    ", ";\n  background-color: ", ";\n  &:focus {\n    box-shadow: 0 0 ", "\n      ", ";\n    background-color: ", ";\n  }\n  &:active {\n    box-shadow: 0 0 ", "\n      ", ";\n    background-color: ", ";\n  }\n"], ["\n  position: absolute;\n  padding: 0;\n  top: ", ";\n  width: ", ";\n  height: ", ";\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n  border-radius: 50%;\n  box-shadow: 0 0 ", "\n    ", ";\n  outline: none;\n  cursor: pointer;\n  border: ", " solid\n    ", ";\n  background-color: ", ";\n  &:focus {\n    box-shadow: 0 0 ", "\n      ", ";\n    background-color: ", ";\n  }\n  &:active {\n    box-shadow: 0 0 ", "\n      ", ";\n    background-color: ", ";\n  }\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.utilityButton.top;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.utilityButton.height;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.utilityButton.height;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.ash;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.snow;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.violet;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.violet;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors["violet80"];
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.ash;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors["violet80"];
});
exports.styles = styles;
var MotionComponent = (0, _styledComponents.default)(_framerMotion.motion.button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), styles);
exports.MotionComponent = MotionComponent;

var Component = _styledComponents.default.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), styles);

exports.Component = Component;
var templateObject_1, templateObject_2, templateObject_3;