import { bankId } from '../config';

export default {
  BANK_ID_CALLBACK: new URL(bankId.redirectUri).pathname,
  LOGIN: '/logg-inn',
  CALLBACK: '/callback',
  CALLBACK_AUTH0: '/authcb',
  HOME: '/hjem',
  ORDER: '/order/:id',
  HELP: '/hjelp',
  RESET_USER_PW: '/passord',
  BENEFITS: '/fordeler',
  APARTMENTS: '/boliger',
  APARTMENTS_INTERESTS: '/boliger/meldte-forkjop',
  APARTMENTS_FAVORITES: '/boliger/favoritter',
  APARTMENTS_FILTERS: '/boliger/filter',
  PREEMPTION: '/meld-forkjop/:id',
  PREEMPTION_BANK_ID: '/meld-forkjop/:id/bankid',
  PREEMPTION_CONTACT_INFO: '/meld-forkjop/:id/kontaktinfo',
  PREEMPTION_OVERVIEW: '/meld-forkjop/:id/oversikt',
  PREEMPTION_PROOF: '/meld-forkjop/:id/finansieringsbevis',
  PREEMPTION_SUMMARY: '/meld-forkjop/:id/oppsummering',
  PREEMPTION_SUCCESS: '/meld-forkjop/:id/suksess',
  INVOICES: '/faktura',
  INVOICES_INVOICE: '/faktura/:id',
  ME: '/meg',
  ME_PROFILE: '/meg/profil',
  ME_MEMBERSHIP: '/meg/medlemskap',
  ME_PREFERENCES: '/meg/preferanser',
  ME_THIRDPARTYINFORMATION: '/meg/ligningsoppgaver',
  MESSAGES: '/meldinger',
} as const;
