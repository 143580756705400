"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "icon", {
  enumerable: true,
  get: function get() {
    return _icon.default;
  }
});
Object.defineProperty(exports, "primary", {
  enumerable: true,
  get: function get() {
    return _primary.default;
  }
});
Object.defineProperty(exports, "quaternary", {
  enumerable: true,
  get: function get() {
    return _quaternary.default;
  }
});
Object.defineProperty(exports, "quinary", {
  enumerable: true,
  get: function get() {
    return _quinary.default;
  }
});
Object.defineProperty(exports, "secondary", {
  enumerable: true,
  get: function get() {
    return _secondary.default;
  }
});
Object.defineProperty(exports, "senary", {
  enumerable: true,
  get: function get() {
    return _senary.default;
  }
});
Object.defineProperty(exports, "tertiary", {
  enumerable: true,
  get: function get() {
    return _tertiary.default;
  }
});
Object.defineProperty(exports, "septenary", {
  enumerable: true,
  get: function get() {
    return _septenary.default;
  }
});

var _icon = _interopRequireDefault(require("./icon"));

var _primary = _interopRequireDefault(require("./primary"));

var _quaternary = _interopRequireDefault(require("./quaternary"));

var _quinary = _interopRequireDefault(require("./quinary"));

var _secondary = _interopRequireDefault(require("./secondary"));

var _senary = _interopRequireDefault(require("./senary"));

var _tertiary = _interopRequireDefault(require("./tertiary"));

var _septenary = _interopRequireDefault(require("./septenary"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}