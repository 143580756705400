"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Summary = exports.ButtonAnimation = exports.AnimationContainer = exports.Card = exports.Details = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _framerMotion = require("../framerMotion");

var _Card = _interopRequireDefault(require("../Card"));

var _card = require("../card/card.styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Details = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
exports.Details = Details;
var Card = (0, _styledComponents.default)(_Card.default)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", " {\n    padding: ", "\n      ", ";\n  }\n\n  min-height: ", ";\n"], ["\n  ", " {\n    padding: ", "\n      ", ";\n  }\n\n  min-height: ", ";\n"])), _card.ScrollableContent, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.accordion.minHeight;
});
exports.Card = Card;
var AnimationContainer = (0, _styledComponents.default)(_framerMotion.motion.div).attrs(function () {
  return {
    initial: false
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
exports.AnimationContainer = AnimationContainer;
var ButtonAnimation = (0, _styledComponents.default)(_framerMotion.motion.div).attrs(function (_a) {
  var open = _a.open,
      theme = _a.theme;
  return {
    style: {
      width: theme.variables.sizes.accordion.buttonAnimation.width,
      height: theme.variables.sizes.accordion.buttonAnimation.height
    },
    initial: {
      originX: 0.5,
      rotate: 90
    },
    animate: {
      originX: 0.5,
      rotate: open ? 270 : 90
    },
    exit: {
      originX: 0.5,
      rotate: 90
    }
  };
})(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
exports.ButtonAnimation = ButtonAnimation;

var Summary = _styledComponents.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));

exports.Summary = Summary;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;