"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "primary", {
  enumerable: true,
  get: function get() {
    return _primary.default;
  }
});
Object.defineProperty(exports, "secondary", {
  enumerable: true,
  get: function get() {
    return _secondary.default;
  }
});

var _primary = _interopRequireDefault(require("./primary"));

var _secondary = _interopRequireDefault(require("./secondary"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}