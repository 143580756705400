"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  unmask: true,
  mask: /^\S*@?\S*$/
};
exports.default = _default;