"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Container = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = _styledComponents.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: ", ";\n  margin-top: ", ";\n  background-color: ", ";\n  height: ", ";\n  \n  ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: ", ";\n  margin-top: ", ";\n  background-color: ", ";\n  height: ", ";\n  \n  ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors["lighter-grey"];
}, function (_a) {
  var height = _a.height,
      theme = _a.theme;
  return height || theme.variables.sizes.card.imageMaxHeight;
}, function (_a) {
  var fullWidth = _a.fullWidth,
      theme = _a.theme;
  return fullWidth && (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      width: calc(\n        100% + ", " +\n          ", "\n      );\n      margin-left: -", ";\n    "], ["\n      width: calc(\n        100% + ", " +\n          ", "\n      );\n      margin-left: -", ";\n    "])), theme.variables.sizes.doublePadding, theme.variables.sizes.doublePadding, theme.variables.sizes.doublePadding);
});

exports.Container = Container;
var templateObject_1, templateObject_2;