"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CloseButton = exports.Banner = exports.Image = exports.Header = exports.Actions = exports.ScrollableContent = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Button = _interopRequireDefault(require("../Button"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = _styledComponents.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: ", ";\n  background-color: var(--bob-core-components-color-snow);\n  box-shadow: 0 0 ", " 0\n    var(--bob-core-components-color-lightblue-shadow-50);\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  border-radius: ", ";\n"], ["\n  min-height: ", ";\n  background-color: var(--bob-core-components-color-snow);\n  box-shadow: 0 0 ", " 0\n    var(--bob-core-components-color-lightblue-shadow-50);\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  border-radius: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.card.minHeight;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.doublePadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
});

exports.Container = Container;

var ScrollableContent = _styledComponents.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n"], ["\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.doublePadding;
});

exports.ScrollableContent = ScrollableContent;

var Actions = _styledComponents.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: auto;\n  padding-top: ", ";\n  justify-self: flex-end;\n  display: flex;\n  flex-direction: column;\n  > * {\n    margin-bottom: ", ";\n  }\n"], ["\n  margin-top: auto;\n  padding-top: ", ";\n  justify-self: flex-end;\n  display: flex;\n  flex-direction: column;\n  > * {\n    margin-bottom: ", ";\n  }\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.doublePadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
});

exports.Actions = Actions;

var Header = _styledComponents.default.h2.attrs(function () {
  return {};
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  font-weight: bold;\n  display: inline-flex;\n  align-items: center;\n  padding-bottom: ", ";\n"], ["\n  font-size: ", ";\n  color: ", ";\n  font-weight: bold;\n  display: inline-flex;\n  align-items: center;\n  padding-bottom: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fontSizes["x-large"];
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.violet;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
});

exports.Header = Header;

var Image = _styledComponents.default.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  object-fit: ", ";\n  align-self: center;\n  padding-bottom: ", ";\n  padding-top: ", ";\n  max-height: ", ";\n  width: ", ";\n"], ["\n  object-fit: ", ";\n  align-self: center;\n  padding-bottom: ", ";\n  padding-top: ", ";\n  max-height: ", ";\n  width: ", ";\n"])), function (_a) {
  var _b = _a.objectFit,
      objectFit = _b === void 0 ? "contain" : _b;
  return objectFit;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.card.imageMaxHeight;
}, function (_a) {
  var fullWidth = _a.fullWidth,
      theme = _a.theme;
  return fullWidth ? "calc(100% + " + theme.variables.sizes.doublePadding + " + " + theme.variables.sizes.doublePadding + ")" : "100%";
});

exports.Image = Image;

var Banner = _styledComponents.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", "\n    ", ";\n  margin: ", ";\n  width: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", "\n    ", ";\n  margin: ", ";\n  width: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.colors["violet-darker"];
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.doublePadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding + " 0 " + theme.variables.sizes.padding + " -" + theme.variables.sizes.doublePadding;
}, function (_a) {
  var theme = _a.theme;
  return "calc(100% + " + theme.variables.sizes.doublePadding + " + " + theme.variables.sizes.doublePadding + ")";
});

exports.Banner = Banner;
var CloseButton = (0, _styledComponents.default)(_Button.default).attrs(function () {
  return {
    variant: "icon",
    iconName: "Close"
  };
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: ", ";\n  position: absolute;\n  top: 0;\n  right: 0;\n"], ["\n  padding: ", ";\n  position: absolute;\n  top: 0;\n  right: 0;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
});
exports.CloseButton = CloseButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;