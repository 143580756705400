"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = exports.Heading = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Input = _interopRequireDefault(require("../Input"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = _styledComponents.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));

exports.Container = Container;

var Heading = _styledComponents.default.h3.attrs(function () {
  return {
    className: 'bob-core-components-typography__regular--large--coal'
  };
})(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))); // export const Checkbox = styled(Input).attrs(() => ({
//   type: 'checkbox',
// }))`
//   margin-top: 0.5rem;
// `;


exports.Heading = Heading;
var Checkbox = (0, _styledComponents.default)(_Input.default).attrs(function () {
  return {
    type: 'checkbox'
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n"], ["\n  margin-top: 0.5rem;\n"])));
exports.Checkbox = Checkbox;
var templateObject_1, templateObject_2, templateObject_3;