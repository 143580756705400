"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonGrid = exports.Image = exports.Card = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Card2 = _interopRequireDefault(require("../Card"));

var _Grid = _interopRequireDefault(require("../Grid"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Card = (0, _styledComponents.default)(_Card2.default)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0;\n  min-height: unset;\n"], ["\n  padding: 0;\n  min-height: unset;\n"])));
exports.Card = Card;

var Image = _styledComponents.default.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: ", ";\n"], ["\n  max-width: ", ";\n"])), function (_a) {
  var _b = _a.maxWidth,
      maxWidth = _b === void 0 ? '20rem' : _b;
  return maxWidth;
});

exports.Image = Image;
var ButtonGrid = (0, _styledComponents.default)(_Grid.default).attrs(function () {
  return {
    item: true
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
  var hasImage = _a.hasImage,
      theme = _a.theme;
  return hasImage && "margin-top: calc(-" + theme.variables.sizes.padding + " * 3)";
});
exports.ButtonGrid = ButtonGrid;
var templateObject_1, templateObject_2, templateObject_3;