export const CHANGE_SCROLL_DIRECTION = 'ui/app/CHANGE_SCROLL_DIRECTION';
export const CHANGE_BROWSER_THEME_COLOR = 'ui/app/CHANGE_BROWSER_THEME_COLOR';
export const TOGGLE_FEATURE_FLAGS_MODAL = 'ui/app/TOGGLE_FEATURE_FLAGS_MODAL';

export const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

export const CHANGE_FEATURE_FLAGS = 'ui/app/CHANGE_FEATURE_FLAGS';
export const SET_COMING_SOON_MODAL = 'ui/app/SET_COMING_SOON_MODAL';
