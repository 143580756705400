"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTypography = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var getFontSize = function getFontSize(_a) {
  var size = _a.size,
      theme = _a.theme;
  if (!size) return theme.variables.sizes.fontSizes.defaultSize;
  return theme.variables.sizes.fontSizes[size] ? theme.variables.sizes.fontSizes[size] : size;
};

var getColor = function getColor(_a) {
  var color = _a.color,
      theme = _a.theme;
  if (!color) return theme.variables.colors.default;
  return theme.variables.colors[color] || color;
};

var StyledTypography = _styledComponents.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n  ", ";\n"], ["\n  text-align: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  color: ", ";\n  ", ";\n"])), function (_a) {
  var _b = _a.textAlign,
      textAlign = _b === void 0 ? 'left' : _b;
  return textAlign;
}, function (_a) {
  var _b = _a.fontWeight,
      fontWeight = _b === void 0 ? "400" : _b;
  return fontWeight;
}, getFontSize, getColor, function (_a) {
  var gutterBottom = _a.gutterBottom,
      theme = _a.theme;
  return gutterBottom && "padding-bottom: " + theme.variables.sizes.gutterBottom;
});

exports.StyledTypography = StyledTypography;
var templateObject_1;