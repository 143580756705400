export default {
  'nb-NO': {
    'Sjekk ut disse boligene!': 'Sjekk ut disse boligene!',
    'Hva er forkjopsrett ?': 'Hva er forkjøpsrett ?',
    Velkommen: 'Velkommen',
    'Gå til innlogging': 'Gå til innlogging',
    'Ansiennitet fra': 'Ansiennitet fra',
    Hei: 'Hei',
    'Logg inn': 'Logg inn',
    'Gi et BOB-medlemskap i gave': 'Gi et BOB-medlemskap i gave',
    Faktura: 'Faktura',
    Fordeler: 'Fordeler',
    Årsmøte: 'Årsmøte',
    Styreportal: 'Styreportal',
  },
};
