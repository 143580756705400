"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/features/object/assign");

require("core-js/features/string/repeat");

require("core-js/features/string/pad-start");

require("core-js/features/string/pad-end");

require("core-js/features/global-this");

require("core-js/features/array/find");

require("core-js/features/promise");

require("core-js/features/string/ends-with");

require("core-js/features/symbol/for");

require("core-js/features/weak-set");

var _EmailPhone = _interopRequireDefault(require("./predefinedMask/EmailPhone"));

var _Phone = _interopRequireDefault(require("./predefinedMask/Phone"));

var _Email = _interopRequireDefault(require("./predefinedMask/Email"));

var _DateOfBirth = _interopRequireDefault(require("./predefinedMask/DateOfBirth"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  emailPhone: _EmailPhone.default,
  email: _Email.default,
  phone: _Phone.default,
  dateOfBirth: _DateOfBirth.default
};
exports.default = _default;