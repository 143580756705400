"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTheme = exports.THEME_KEY = void 0;

var _variables = _interopRequireDefault(require("./variables"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var __assign = void 0 && (void 0).__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var fontSize = 16;
var coefficient = fontSize / 16; // Default rem size;
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createTypography.js

var pxToRem = function pxToRem(htmlFontSize, size) {
  if (htmlFontSize === void 0) {
    htmlFontSize = fontSize;
  }

  return size / htmlFontSize * coefficient + "rem";
};

var traverseSizes = function traverseSizes(originalSizes, converter) {
  var sizes = __assign({}, originalSizes);

  var converted = {};
  Object.keys(sizes).forEach(function (key) {
    if (Object.prototype.hasOwnProperty.call(sizes, key)) {
      var k = key;

      if (_typeof(sizes[k]) === "object") {
        converted[k] = traverseSizes(sizes[k], converter);
      } else if (typeof sizes[k] === "number") {
        converted[k] = converter(sizes[k]);
      } else {
        throw new Error("Invalid format in variables object.");
      }
    }
  });
  return converted;
}; //https://github.com/mui-org/material-ui/blob/master/packages/material-ui-utils/src/deepmerge.jshttps://github.com/mui-org/material-ui/blob/master/packages/material-ui-utils/src/deepmerge.js


function isPlainObject(item) {
  return item && _typeof(item) === "object" && item.constructor === Object;
} //https://github.com/mui-org/material-ui/blob/master/packages/material-ui-utils/src/deepmerge.js


function deepmerge(target, source, options) {
  if (options === void 0) {
    options = {
      clone: true
    };
  }

  var output = options.clone ? __assign({}, target) : target;

  if (isPlainObject(target) && isPlainObject(source)) {
    Object.keys(source).forEach(function (key) {
      // Avoid prototype pollution
      if (key === "__proto__") {
        return;
      }

      if (isPlainObject(source[key]) && key in target) {
        output[key] = deepmerge(target[key], source[key], options);
      } else {
        output[key] = source[key];
      }
    });
  }

  return output;
}

var THEME_KEY = "__BOB_CORE_COMPONENTS_THEME_PROVIDER__";
exports.THEME_KEY = THEME_KEY;

var createTheme = function createTheme(theme) {
  var _a;

  var _b;

  var converter = (theme === null || theme === void 0 ? void 0 : theme.pxToRem) ? theme === null || theme === void 0 ? void 0 : theme.pxToRem : pxToRem.bind(pxToRem, theme === null || theme === void 0 ? void 0 : theme.htmlFontSize);

  var spacing = function spacing(value) {
    return converter(8 * value);
  };

  var defaultTheme = (_a = {}, _a[THEME_KEY] = true, _a.mode = "light", _a.spacing = spacing, _a.breakpoints = _variables.default.breakpoints, _a.variables = __assign(__assign({}, _variables.default), {
    sizes: traverseSizes(_variables.default.sizes, converter)
  }), _a);

  if (theme) {
    var overrides = JSON.parse(JSON.stringify(theme));

    if ((_b = overrides === null || overrides === void 0 ? void 0 : overrides.variables) === null || _b === void 0 ? void 0 : _b.sizes) {
      overrides.variables.sizes = traverseSizes(overrides.variables.sizes, converter);
    }

    return deepmerge(defaultTheme, overrides, {
      clone: true
    });
  }

  return defaultTheme;
};

exports.createTheme = createTheme;