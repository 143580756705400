"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "warning", {
  enumerable: true,
  get: function get() {
    return _warning.default;
  }
});
Object.defineProperty(exports, "regular", {
  enumerable: true,
  get: function get() {
    return _regular.default;
  }
});
Object.defineProperty(exports, "blank", {
  enumerable: true,
  get: function get() {
    return _blank.default;
  }
});

var _warning = _interopRequireDefault(require("./warning"));

var _regular = _interopRequireDefault(require("./regular"));

var _blank = _interopRequireDefault(require("./blank"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}