"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledButton = exports.TextContainerSpan = exports.ArrowContainer = exports.ContentSpan = exports.IconContainer = exports.TextSpan = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTheming = _interopRequireDefault(require("styled-theming"));

var variants = _interopRequireWildcard(require("./variants"));

var _config = require("../typography/config");

var _constants = require("./constants");

var _helpers = require("./helpers");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var variantStyling = _styledTheming.default.variants('mode', 'variant', _objectSpread({
  default: variants.primary
}, variants));

var TextSpan = _styledComponents.default.span.attrs(function () {
  return {
    className: "".concat(_constants.NAME, "__textspan")
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  vertical-align: text-top;\n  display: inline-block;\n"])));

exports.TextSpan = TextSpan;

var IconContainer = _styledComponents.default.span.attrs(function () {
  return {
    className: "".concat(_constants.NAME, "__icon-container")
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  z-index: 0;\n"])));

exports.IconContainer = IconContainer;

var ContentSpan = _styledComponents.default.span.attrs(function () {
  return {
    className: "".concat(_constants.NAME, "__contentspan")
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: inline-flex;\n  align-items: center;\n"])));

exports.ContentSpan = ContentSpan;

var ArrowContainer = _styledComponents.default.span.attrs(function () {
  return {
    className: "".concat(_constants.NAME, "__arrow-container")
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: ", ";\n  width: ", ";\n  svg {\n    height: ", ";\n    width: ", ";\n    rect {\n      transition: width 300ms cubic-bezier(0.78, 0.02, 0.58, 1);\n    }\n    path {\n      transition: transform 300ms cubic-bezier(0.78, 0.02, 0.58, 1);\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.variables.sizes.button.arrowContainerHeight;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.variables.sizes.button.arrowContainerHeight;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.variables.sizes.button.arrowContainerHeight;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.variables.sizes.button.arrowContainerHeight;
}); // Hack to show text above yellow animated background. SHould be other way to do it, but demo time.


exports.ArrowContainer = ArrowContainer;

var TextContainerSpan = _styledComponents.default.span.attrs(function () {
  return {
    className: "".concat(_constants.NAME, "__textcontainerspan")
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  z-index: 0;\n  display: inline-flex;\n  align-items: center;\n\n  ", " {\n    svg {\n      rect {\n        width: ", ";\n      }\n      path {\n        transform: translateX(", ");\n      }\n    }\n    ", "\n    ", ";\n  }\n"])), function (_ref5) {
  var direction = _ref5.direction,
      showArrow = _ref5.showArrow;
  if (!showArrow) return '';

  switch (direction) {
    case _constants.ARROW_DIRECTION.RIGHT:
      return (0, _styledComponents.css)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n          margin-left: ", ";\n          ", " {\n            padding-right: ", ";\n          }\n        "])), function (_ref6) {
        var theme = _ref6.theme;
        return theme.variables.sizes.padding;
      }, TextSpan, function (_ref7) {
        var theme = _ref7.theme;
        return theme.variables.sizes.halfPadding;
      });

    case _constants.ARROW_DIRECTION.LEFT:
      return (0, _styledComponents.css)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n          margin-right: ", ";\n          ", " {\n            padding-left: ", ";\n          }\n        "])), function (_ref8) {
        var theme = _ref8.theme;
        return theme.variables.sizes.padding;
      }, TextSpan, function (_ref9) {
        var theme = _ref9.theme;
        return theme.variables.sizes.halfPadding;
      });

    default:
      return '';
  }
}, ArrowContainer, _helpers.getRectLength, _helpers.getRectLength, function (_ref10) {
  var position = _ref10.position;
  return position === 'left' && (0, _styledComponents.css)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n        order: -1;\n      "])));
}, function (_ref11) {
  var direction = _ref11.direction;
  return direction === _constants.ARROW_DIRECTION.LEFT ? (0, _styledComponents.css)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n            transform: rotate(180deg);\n          "]))) : direction === _constants.ARROW_DIRECTION.DOWN ? (0, _styledComponents.css)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n            transform: rotate(90deg);\n          "]))) : direction === _constants.ARROW_DIRECTION.UP ? (0, _styledComponents.css)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n            transform: rotate(270deg);\n          "]))) : '';
});

exports.TextContainerSpan = TextContainerSpan;

var StyledButton = _styledComponents.default.button(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: none;\n  cursor: pointer;\n  background-color: transparent;\n  ", ";\n  text-align: center;\n  ", ";\n\n  &:hover,\n  &:active,\n  &:focus {\n    ", " {\n      svg {\n        rect {\n          width: ", ";\n        }\n        path {\n          transform: translateX(", ");\n        }\n      }\n    }\n  }\n  &:disabled {\n    ", " {\n      fill: var(--bob-core-components-color-eggplant40);\n    }\n  }\n  ", "\n  ", "\n"])), _config.variables['bob-core-components-typography__semibold--medium-1--coal'], function (_ref12) {
  var fitContent = _ref12.fitContent;
  return fitContent && 'width: fit-content';
}, ArrowContainer, _helpers.getAnimatedRectLength, _helpers.getAnimatedRectLength, ArrowContainer, function (_ref13) {
  var loading = _ref13.loading;
  return loading && (0, _styledComponents.css)(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n      ", " {\n        margin-right: ", ";\n      }\n    "])), TextContainerSpan, function (_ref14) {
    var theme = _ref14.theme;
    return theme.variables.sizes.padding;
  });
}, variantStyling);

exports.StyledButton = StyledButton;