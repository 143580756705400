"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledGrid = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var __rest = void 0 && (void 0).__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var SPACINGS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
var GRID_SIZES = ["auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; // function generateGrid(globalStyles, theme, breakpoint) {

function generateGrid(_a) {
  var theme = _a.theme,
      other = __rest(_a, ["theme"]);

  if (!theme) throw new Error('[Grid] Generate grid Theme is not defined');
  var breaks = {};
  var otherAsBP = other;
  Object.keys(theme.breakpoints).forEach(function (k) {
    var key = k;
    if (otherAsBP[key]) breaks[key] = otherAsBP[key];
  });

  var add = function add(prop, val) {
    if (GRID_SIZES.findIndex(function (s) {
      return s === val;
    }) <= -1) throw new Error("GRID SIZE not supported");

    if (val === true) {
      // For the auto layouting
      return (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        flex-basis: 0;\n        flex-grow: 1;\n        max-width: 100%;\n      "], ["\n        flex-basis: 0;\n        flex-grow: 1;\n        max-width: 100%;\n      "])));
    }

    if (val === "auto") {
      return (0, _styledComponents.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        flex-basis: auto;\n        flex-grow: 0;\n        max-width: none;\n      "], ["\n        flex-basis: auto;\n        flex-grow: 0;\n        max-width: none;\n      "])));
    } // Keep 7 significant numbers.


    var width = Math.round(val / 12 * 10e7) / 10e5 + "%";
    if (prop === "xs") // no need for breakpoint on xs
      return (0, _styledComponents.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        flex-basis: ", ";\n        flex-grow: 0;\n        max-width: ", ";\n      "], ["\n        flex-basis: ", ";\n        flex-grow: 0;\n        max-width: ", ";\n      "])), width, width); // Close to the bootstrap implementation:
    // https://github.com/twbs/bootstrap/blob/8fccaa2439e97ec72a4b7dc42ccc1f649790adb0/scss/mixins/_grid.scss#L41

    return (0, _styledComponents.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      ", " {\n        flex-basis: ", ";\n        flex-grow: 0;\n        max-width: ", ";\n      }\n    "], ["\n      ", " {\n        flex-basis: ", ";\n        flex-grow: 0;\n        max-width: ", ";\n      }\n    "])), theme.breakpoints.up(prop), width, width);
  };

  var styles = [];
  if (breaks.xs) styles.push(add("xs", breaks.xs));
  if (breaks.sm) styles.push(add("sm", breaks.sm));
  if (breaks.md) styles.push(add("md", breaks.md));
  if (breaks.lg) styles.push(add("lg", breaks.lg));
  if (breaks.xl) styles.push(add("xl", breaks.xl));
  return styles;
}

function getOffset(val, div) {
  if (div === void 0) {
    div = 1;
  }

  var parse = parseFloat(val);
  return "" + parse / div + (String(val).replace(String(parse), "") || "rem");
}

var generateGutter = function generateGutter(_a) {
  var theme = _a.theme,
      container = _a.container,
      spacing = _a.spacing;
  if (!theme) throw new Error('[Grid] Theme is not defined');
  var s = SPACINGS.find(function (sp) {
    return spacing === sp;
  }) || 0;
  var themeSpacing = theme.spacing(s);

  if (!container || themeSpacing === "0px" || themeSpacing === "0rem") {
    return;
  }

  return (0, _styledComponents.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: -", ";\n    width: calc(100% + ", ");\n\n    & > .item {\n      padding: ", ";\n    }\n  "], ["\n    margin: -", ";\n    width: calc(100% + ", ");\n\n    & > .item {\n      padding: ", ";\n    }\n  "])), getOffset(themeSpacing, 2), getOffset(themeSpacing), getOffset(themeSpacing, 2));
}; // Default CSS values
// // flex: '0 1 auto',
// // flexDirection: 'row',
// // alignItems: 'flex-start',
// // flexWrap: 'nowrap',
// // justifyContent: 'flex-start',


var container = function container(_a) {
  var container = _a.container;
  return container && (0, _styledComponents.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    box-sizing: border-box;\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n  "], ["\n    box-sizing: border-box;\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n  "])));
};

var root = function root() {
  return (0, _styledComponents.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
};

var item = function item(_a) {
  var item = _a.item;
  return item && (0, _styledComponents.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
};

var zeroMinWidth = function zeroMinWidth(_a) {
  var zeroMinWidth = _a.zeroMinWidth;
  return zeroMinWidth && (0, _styledComponents.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
};

var direction = function direction(_a) {
  var _b = _a.direction,
      direction = _b === void 0 ? 'row' : _b;
  return (0, _styledComponents.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["flex-direction: ", ""], ["flex-direction: ", ""])), direction);
};

var wrap = function wrap(_a) {
  var _b = _a.wrap,
      wrap = _b === void 0 ? 'wrap' : _b;
  return (0, _styledComponents.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["flex-wrap: ", ""], ["flex-wrap: ", ""])), wrap);
};

var alignItems = function alignItems(_a) {
  var _b = _a.alignItems,
      alignItems = _b === void 0 ? "stretch" : _b;
  return (0, _styledComponents.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  align-items: ", ";\n"], ["\n  align-items: ", ";\n"])), alignItems);
};

var alignContent = function alignContent(_a) {
  var _b = _a.alignContent,
      alignContent = _b === void 0 ? "stretch" : _b;
  return (0, _styledComponents.css)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  align-content: ", ";\n"], ["\n  align-content: ", ";\n"])), alignContent);
};

var justifyContent = function justifyContent(_a) {
  var _b = _a.justifyContent,
      justifyContent = _b === void 0 ? "flex-start" : _b;
  return (0, _styledComponents.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    justify-content: ", ";\n  "], ["\n    justify-content: ", ";\n  "])), justifyContent);
};

var StyledGrid = _styledComponents.default.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), root, container, item, zeroMinWidth, direction, wrap, alignContent, alignItems, justifyContent, generateGutter, generateGrid);

exports.StyledGrid = StyledGrid;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;