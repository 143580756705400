"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DefaultCss = exports.GlobalStyle = void 0;

var _styledComponents = require("styled-components");

var _styledNormalize = _interopRequireDefault(require("styled-normalize"));

var _colors = _interopRequireDefault(require("../theme/colors"));

var _config = _interopRequireWildcard(require("../typography/config"));

var _font = _interopRequireDefault(require("../theme/font"));

var _templateObject, _templateObject2;

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var GlobalStyle = (0, _styledComponents.createGlobalStyle)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  \n  ", ";\n  ", ";\n  ", ";\n  ", "\n  \n  *,\n*::before,\n*::after{box-sizing:border-box;}\n\n  :root {\n    ", ";\n    font-family: 'SofiaPro', sans-serif;\n  }\n  button {\n    padding: 0;\n  }\n"])), _font.default, _styledNormalize.default, _config.default, _config.componentsReset, _colors.default);
exports.GlobalStyle = GlobalStyle;
var DefaultCss = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\n  ", ";\n  ", ";\n  ", ";\n  ", "\n\n  *,\n*::before,\n*::after{box-sizing:border-box;}\n\n    ", ";\n    font-family: 'SofiaPro', sans-serif;\n\n"])), _font.default, _styledNormalize.default, _config.default, _config.componentsReset, _colors.default);
exports.DefaultCss = DefaultCss;
var _default = GlobalStyle;
exports.default = _default;