"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Container = exports.HiddenRadio = exports.StyledRadio = exports.Label = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTheming = _interopRequireDefault(require("styled-theming"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var base = (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: transparent;\n  padding: 0;\n  border: none;\n"], ["\n  background-color: transparent;\n  padding: 0;\n  border: none;\n"])));

var activeColor = _styledTheming.default.variants('mode', 'variant', {
  default: {
    light: '--bob-core-components-color-violet80',
    dark: '--bob-core-components-color-violet80'
  },
  error: {
    light: '--bob-core-components-color-rosso20',
    dark: '--bob-core-components-color-rosso20'
  }
});

var borderColor = _styledTheming.default.variants('mode', 'variant', {
  default: {
    light: '--bob-core-components-color-violet80',
    dark: '--bob-core-components-color-violet80'
  },
  error: {
    light: '--bob-core-components-color-rosso',
    dark: '--bob-core-components-color-rosso'
  }
});

var borderHoverColor = _styledTheming.default.variants('mode', 'variant', {
  default: {
    light: '--bob-core-components-color-violet40',
    dark: '--bob-core-components-color-violet40'
  },
  error: {
    light: '--bob-core-components-color-rosso40',
    dark: '--bob-core-components-color-rosso40'
  }
});

var Label = _styledComponents.default.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 0.5rem;\n  ", ";\n"], ["\n  padding-left: 0.5rem;\n  ", ";\n"])), function (_a) {
  var disabled = _a.disabled;
  return disabled && 'cursor: default; pointer-events: none;';
});

exports.Label = Label;

var StyledRadio = _styledComponents.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  width: 1.5rem;\n  height: 1.5rem;\n  border: solid 0.125rem var(", ");\n  border-radius: 50%;\n  position: relative;\n  padding: 0.25rem;\n\n  &:after {\n    content: '';\n    width: calc(100% - 0.5rem);\n    height: calc(100% - 0.5rem);\n    position: absolute;\n    background-color: var(", ");\n    border-radius: 50%;\n  }\n\n  ", ";\n"], ["\n  ", ";\n  width: 1.5rem;\n  height: 1.5rem;\n  border: solid 0.125rem var(", ");\n  border-radius: 50%;\n  position: relative;\n  padding: 0.25rem;\n\n  &:after {\n    content: '';\n    width: calc(100% - 0.5rem);\n    height: calc(100% - 0.5rem);\n    position: absolute;\n    background-color: var(", ");\n    border-radius: 50%;\n  }\n\n  ", ";\n"])), base, borderColor, borderColor, function (_a) {
  var disabled = _a.disabled;
  return disabled && 'cursor: default; pointer-events: none;';
});

exports.StyledRadio = StyledRadio;

var HiddenRadio = _styledComponents.default.input.attrs(function () {
  return {
    type: 'radio'
  };
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  opacity: 0;\n  width: 1.5rem;\n  height: 1.5rem;\n  cursor: pointer;\n  z-index: 1;\n  ", ";\n\n  &:hover,\n  &:focus {\n    & + ", " {\n      border-color: var(", ");\n      &:after {\n        background-color: var(", ");\n      }\n    }\n  }\n  &:active {\n    & + ", " {\n      border: none;\n      &:after {\n        display: unset;\n        background-color: var(", ");\n      }\n    }\n  }\n  & + ", " {\n    &:after {\n      display: none;\n    }\n  }\n  &:checked {\n    & + ", " {\n      &:after {\n        display: unset;\n      }\n    }\n  }\n"], ["\n  position: absolute;\n  opacity: 0;\n  width: 1.5rem;\n  height: 1.5rem;\n  cursor: pointer;\n  z-index: 1;\n  ", ";\n\n  &:hover,\n  &:focus {\n    & + ", " {\n      border-color: var(", ");\n      &:after {\n        background-color: var(", ");\n      }\n    }\n  }\n  &:active {\n    & + ", " {\n      border: none;\n      &:after {\n        display: unset;\n        background-color: var(", ");\n      }\n    }\n  }\n  & + ", " {\n    &:after {\n      display: none;\n    }\n  }\n  &:checked {\n    & + ", " {\n      &:after {\n        display: unset;\n      }\n    }\n  }\n"])), function (_a) {
  var disabled = _a.disabled;
  return disabled && 'cursor: default; pointer-events: none;';
}, StyledRadio, borderHoverColor, borderHoverColor, StyledRadio, activeColor, StyledRadio, StyledRadio);

exports.HiddenRadio = HiddenRadio;

var Container = _styledComponents.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  align-items: center;\n\n  ", ";\n"], ["\n  display: flex;\n  position: relative;\n  align-items: center;\n\n  ", ";\n"])), function (_a) {
  var disabled = _a.disabled;
  return disabled && 'opacity: 0.5';
});

exports.Container = Container;
var _default = StyledRadio;
exports.default = _default;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;