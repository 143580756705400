"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {};
exports.default = void 0;

var _globalStyles = require("./globalStyles/globalStyles.styles");

Object.keys(_globalStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _globalStyles[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _globalStyles[key];
    }
  });
});
var _default = _globalStyles.GlobalStyle;
exports.default = _default;