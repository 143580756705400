"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("react-imask");
/* globals IMask */

/* IMask is undefined in iOS 11*/


var _default = {
  unmask: true,
  pattern: 'dmY',
  lazy: false,
  mask: Date,
  format: function format(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear().toString().substr(-2);
    if (day < 10) day = "0".concat(day);
    if (month < 10) month = "0".concat(month);
    return "".concat(day).concat(month).concat(year);
  },
  parse: function parse(str) {
    var d = parseInt(str.substring(0, 2), 10);
    var m = parseInt(str.substring(2, 4), 10);
    var y = parseInt(str.substring(4, 6), 10);
    return new Date(y, m - 1, d);
  },
  blocks: {
    d: {
      mask: IMask.MaskedRange,
      placeholderChar: 'd',
      from: 1,
      to: 31,
      maxLength: 2
    },
    m: {
      mask: IMask.MaskedRange,
      placeholderChar: 'm',
      from: 1,
      to: 12,
      maxLength: 2
    },
    Y: {
      mask: '00',
      placeholderChar: 'å',
      from: 0,
      to: 99,
      maxLength: 3
    }
  }
};
exports.default = _default;