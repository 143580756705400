"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconContainer = exports.TextPadding = exports.Inner = exports.Container = exports.WarningIcon = exports.CheckIcon = exports.AutoSizeText = exports.TextContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTheming = _interopRequireDefault(require("styled-theming"));

var _Icon = _interopRequireDefault(require("../Icon"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var TextContainer = _styledComponents.default.svg.attrs(function () {
  return {
    viewBox: '0 0 32 32'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  padding: 0.2rem;\n"])));

exports.TextContainer = TextContainer;

var AutoSizeText = _styledComponents.default.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));

exports.AutoSizeText = AutoSizeText;
var borderColor = (0, _styledTheming.default)('mode', {
  light: 'var(--bob-core-components-color-violet)',
  dark: 'var(--bob-core-components-color-snow)'
});
var CheckIcon = (0, _styledComponents.default)(_Icon.default).attrs(function () {
  return {
    name: 'Check',
    color: 'success'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
exports.CheckIcon = CheckIcon;
var WarningIcon = (0, _styledComponents.default)(_Icon.default).attrs(function () {
  return {
    name: 'Warning',
    color: 'warning'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
exports.WarningIcon = WarningIcon;

var Container = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: absolute;\n  height: 2.25rem;\n  width: 3rem;\n  left: ", "px;\n  transform: translateX(-50%);\n  border: solid 0.125rem ", ";\n  top: 0;\n\n  &:after {\n    position: absolute;\n    display: block;\n    content: '';\n    border: 0.5rem solid transparent; /*adjust size*/\n    bottom: -1rem;\n    left: 50%;\n    margin-left: -0.5rem;\n    border-top-color: ", "; /*Chevron Color*/\n  }\n"])), function (_ref) {
  var _ref$left = _ref.left,
      left = _ref$left === void 0 ? -999999 : _ref$left;
  return left;
}, borderColor, borderColor);

exports.Container = Container;

var Inner = _styledComponents.default.div.attrs(function (_ref2) {
  var th = _ref2.theme;
  var className = 'bob-core-components-typography__bold--small--violet';
  if (th && th.mode && th.mode === 'dark') className = 'bob-core-components-typography__bold--small--snow';
  return {
    className: className
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  padding: 0.2rem;\n"])));

exports.Inner = Inner;

var TextPadding = _styledComponents.default.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));

exports.TextPadding = TextPadding;

var IconContainer = _styledComponents.default.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  position: absolute;\n  height: 1.5rem;\n  right: -0.75rem;\n  top: -0.75rem;\n  width: 1.5rem;\n\n  > svg {\n    width: 1.5rem;\n    height: 1.5rem;\n    background-color: var(--bob-core-components-color-snow);\n  }\n"])));

exports.IconContainer = IconContainer;