"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.InputError = exports.StyledInput = exports.InputLabel = exports.Adornment = exports.InputContainer = exports.Container = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _Typography = _interopRequireDefault(require("../Typography"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var movedLabelCss = (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  top: -", ";\n  font-size: ", ";\n  color: ", ";\n  left: ", ";\n"], ["\n  top: -", ";\n  font-size: ", ";\n  color: ", ";\n  left: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fontSizes.medium;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.eggplant;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
});

var Container = _styledComponents.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));

exports.Container = Container;

var InputContainer = _styledComponents.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  border-radius: ", ";\n"], ["\n  position: relative;\n  border-radius: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.sixPx;
});

exports.InputContainer = InputContainer;

var Adornment = _styledComponents.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.adornmentWidth;
});

exports.Adornment = Adornment;
var InputLabel = (0, _styledComponents.default)(_Typography.default)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  pointer-events: none;\n  left: ", ";\n  top: ", ";\n  transition: 250ms cubic-bezier(0.78, 0.02, 0.58, 1);\n  background: linear-gradient(\n    to top,\n    ", " 50%,\n    transparent 50%\n  );\n  ", ";\n"], ["\n  position: absolute;\n  pointer-events: none;\n  left: ", ";\n  top: ", ";\n  transition: 250ms cubic-bezier(0.78, 0.02, 0.58, 1);\n  background: linear-gradient(\n    to top,\n    ", " 50%,\n    transparent 50%\n  );\n  ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.doublePadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.snow;
}, function (_a) {
  var stickLabel = _a.stickLabel;
  return stickLabel && movedLabelCss;
});
exports.InputLabel = InputLabel;

var StyledInput = _styledComponents.default.input.attrs(function () {
  return {
    spellCheck: false
  };
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  &&& {\n    line-height: ", ";\n    height: ", ";\n    font-size: ", ";\n    color: ", ";\n  }\n  border: ", " solid\n      ", ";\n  border-radius: ", ";\n  text-indent: calc(\n    ", " -\n      ", "\n  );\n  outline: 0;\n  box-shadow: 0 ", "\n    ", " rgba(50, 14, 59, 0.08);\n  ", ";\n\n  &:hover {\n    box-shadow: 0 ", "\n      ", " rgba(0, 0, 0, 0.08);\n  }\n  &:focus {\n    border: ", " solid\n      ", ";\n  &:focus,\n  &:valid {\n    ~ ", " {\n      ", ";\n    }\n  }\n  &:disabled {\n    border: none;\n    box-shadow: 0 0 0 0;\n    background-color: ", ";\n    ~ ", " {\n      ", ";\n      background: ", ";\n      background-color: ", ";\n    }\n  }\n\n  ", ";\n"], ["\n  width: 100%;\n  &&& {\n    line-height: ", ";\n    height: ", ";\n    font-size: ", ";\n    color: ", ";\n  }\n  border: ", " solid\n      ", ";\n  border-radius: ", ";\n  text-indent: calc(\n    ", " -\n      ", "\n  );\n  outline: 0;\n  box-shadow: 0 ", "\n    ", " rgba(50, 14, 59, 0.08);\n  ", ";\n\n  &:hover {\n    box-shadow: 0 ", "\n      ", " rgba(0, 0, 0, 0.08);\n  }\n  &:focus {\n    border: ", " solid\n      ", ";\n  &:focus,\n  &:valid {\n    ~ ", " {\n      ", ";\n    }\n  }\n  &:disabled {\n    border: none;\n    box-shadow: 0 0 0 0;\n    background-color: ", ";\n    ~ ", " {\n      ", ";\n      background: ", ";\n      background-color: ", ";\n    }\n  }\n\n  ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.inputHeight;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.inputHeight;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fontSizes.medium;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.coal;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.onePx;
}, function (_a) {
  var error = _a.error,
      theme = _a.theme;
  return error ? theme.variables.colors.rosso : theme.variables.colors["violet60"];
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.sixPx;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.twoPx;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var iconName = _a.iconName;
  return iconName && "padding-right: 2.5rem";
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.fourthOfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.onePx;
}, function (_a) {
  var error = _a.error,
      theme = _a.theme;
  return error ? theme.variables.colors.rosso : theme.variables.colors.violet;
}, InputLabel, movedLabelCss, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.platinum;
}, InputLabel, movedLabelCss, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.platinum;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.platinum;
}, function (_a) {
  var placeholder = _a.placeholder;
  return placeholder && "\n    ~ " + InputLabel + " {\n      " + movedLabelCss + "\n     }\n  ";
});

exports.StyledInput = StyledInput;

var InputError = _styledComponents.default.div.attrs(function () {
  return {
    className: "bob-core-components-typography__regular--small--rosso"
  };
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: ", ";\n  margin-bottom: ", ";\n  text-align: start;\n  vertical-align: top;\n"], ["\n  margin-top: ", ";\n  margin-bottom: ", ";\n  text-align: start;\n  vertical-align: top;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
});

exports.InputError = InputError;
var _default = StyledInput;
exports.default = _default;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;