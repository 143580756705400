"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Aside = exports.Container = exports.CloseButton = exports.CloseButtonContainer = exports.Background = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _framerMotion = require("../framerMotion");

var _Card = _interopRequireDefault(require("../Card"));

var _Button = _interopRequireDefault(require("../Button"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Background = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  width: 300px;\n  background: #eee;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  width: 300px;\n  background: #eee;\n"])));
exports.Background = Background;
var CloseButtonContainer = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  ", ";\n  top: 0;\n"], ["\n  position: absolute;\n  ", ";\n  top: 0;\n"])), function (_a) {
  var _b = _a.direction,
      direction = _b === void 0 ? 'left' : _b;
  return direction === 'left' ? 'right: 0' : 'left: 0';
});
exports.CloseButtonContainer = CloseButtonContainer;
var CloseButton = (0, _styledComponents.default)(_Button.default).attrs(function () {
  return {
    variant: "icon",
    fitContent: true,
    iconProps: {
      round: false,
      size: "small"
    },
    iconName: "Close"
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.halfPadding;
});
exports.CloseButton = CloseButton;
var Container = (0, _styledComponents.default)(_Card.default)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
exports.Container = Container;
var Aside = (0, _styledComponents.default)(_framerMotion.motion.aside)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 20rem;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  ", ";\n  bottom: 0;\n"], ["\n  width: 20rem;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  ", ";\n  bottom: 0;\n"])), function (_a) {
  var direction = _a.direction;
  return direction === "right" && "right: -20rem";
});
exports.Aside = Aside;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;