"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BackgroundCircle = exports.Circle = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _framerMotion = require("framer-motion");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = (0, _styledComponents.default)(_framerMotion.motion.svg)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n"])), function (_a) {
  var _b = _a.size,
      size = _b === void 0 ? 'medium' : _b,
      theme = _a.theme;
  return theme.variables.sizes.progressIndicator.circle.sizes[size];
}, function (_a) {
  var _b = _a.size,
      size = _b === void 0 ? 'medium' : _b,
      theme = _a.theme;
  return theme.variables.sizes.progressIndicator.circle.sizes[size];
});
exports.Container = Container;
var Circle = (0, _styledComponents.default)(_framerMotion.motion.path)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", ";\n  stroke-width: ", ";\n  stroke-linecap: round;\n"], ["\n  fill: none;\n  stroke: ", ";\n  stroke-width: ", ";\n  stroke-linecap: round;\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.violet;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.progressIndicator.circle.strokeWidth;
});
exports.Circle = Circle;
var BackgroundCircle = (0, _styledComponents.default)(_framerMotion.motion.path)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", ";\n  stroke-width: ", ";\n"], ["\n  fill: none;\n  stroke: ", ";\n  stroke-width: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.violet20;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.progressIndicator.circle.strokeWidth;
});
exports.BackgroundCircle = BackgroundCircle;
var templateObject_1, templateObject_2, templateObject_3;