export default {
  'nb-NO': {
    'Fødselsdato er på feil format': 'Fødselsdato er på feil format',
    'Fødselsdato må være satt': 'Fødselsdato må være satt',
    'Postnummer må være satt': 'Postnummer må være satt',
    'Postnummer må bestå av fire tall': 'Postnummer må bestå av fire tall',
    'Addresse må være satt': 'Addresse må være satt',
    'Fornavn må være satt': 'Fornavn må være satt',
    'Etternavn må være satt': 'Etternavn må være satt',
    'E-post må være satt': 'E-post må være satt',
    'Telefonnummer må bestå av åtte tall': 'Telefonnummer må bestå av åtte tall',
    'Telefonnummer må være satt': 'Telefonnummer må være satt',
    Fornavn: 'Fornavn',
    Etternavn: 'Etternavn',
    'E-post': 'E-post',
    'Bli medlem': 'Bli medlem',
    Mobil: 'Mobil',
    Postnummer: 'Postnummer',
    Poststed: 'Poststed',
  },
};
