"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _constants = require("../constants");

var _helpers = require("../helpers");

var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var style = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  z-index: 0;\n\n  ", " {\n    margin: 0;\n    padding-right: 0;\n  }\n  ", " {\n    position: relative;\n\n    &:before {\n      transition: height cubic-bezier(0.78, 0.02, 0.58, 1) 250ms;\n      content: \"\";\n      position: absolute;\n      width: 100%;\n      display: block;\n      height: ", ";\n      bottom: 0;\n      left: 0;\n      background-color: var(--bob-core-components-color-grain);\n    }\n  }\n  ", " {\n    margin: 0;\n    width: ", ";\n    svg {\n      width: ", ";\n      rect {\n        width: ", ";\n      }\n      path {\n        transform: translateX(\n          ", "\n        );\n      }\n    }\n  }\n  &:hover,\n  &:focus,\n  &:active {\n    ", " {\n      &:before {\n        height: 50%;\n      }\n    }\n    ", " {\n      margin: 0;\n      svg {\n        rect {\n          width: ", ";\n        }\n        path {\n          transform: translateX(\n            ", "\n          );\n        }\n      }\n    }\n  }\n  &:disabled {\n    opacity: 0.4;\n    pointer-events: none;\n  }\n"])), ".".concat(_constants.NAME, "__textspan"), ".".concat(_constants.NAME, "__contentspan"), function (_ref) {
  var theme = _ref.theme;
  return theme.variables.sizes.twoPx;
}, ".".concat(_constants.NAME, "__arrow-container"), function (_ref2) {
  var theme = _ref2.theme;
  return theme.variables.sizes.button.tertiary.arrowContainerWidth;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.variables.sizes.button.tertiary.arrowContainerWidth;
}, (0, _helpers.getRectLength)({
  arrowLength: _constants.ARROW_LENGTH.MEDIUM
}), (0, _helpers.getRectLength)({
  arrowLength: _constants.ARROW_LENGTH.MEDIUM
}), ".".concat(_constants.NAME, "__contentspan"), ".".concat(_constants.NAME, "__arrow-container"), (0, _helpers.getAnimatedRectLength)({
  arrowLength: _constants.ARROW_LENGTH.MEDIUM
}), (0, _helpers.getAnimatedRectLength)({
  arrowLength: _constants.ARROW_LENGTH.MEDIUM
}));
var dark = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var light = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  background-color: transparent;\n"])), style);
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;