"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  motion: true
};
exports.motion = void 0;

require("./IE11ProxyPolyfill");

var _framerMotion = require("framer-motion");

Object.keys(_framerMotion).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _framerMotion[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _framerMotion[key];
    }
  });
});

// This needs to be imported before framer-motion. Just don't break in IE11. No support guaranteed.
var _motion;

if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1) {
  console.log("IE detected, framer motion using createDomMotionComponent");
  /* Microsoft Internet Explorer detected in. */

  _motion = {
    div: (0, _framerMotion.createDomMotionComponent)("div"),
    span: (0, _framerMotion.createDomMotionComponent)("span"),
    button: (0, _framerMotion.createDomMotionComponent)("button"),
    a: (0, _framerMotion.createDomMotionComponent)("a"),
    article: (0, _framerMotion.createDomMotionComponent)("article"),
    header: (0, _framerMotion.createDomMotionComponent)("header"),
    svg: (0, _framerMotion.createDomMotionComponent)("svg"),
    path: (0, _framerMotion.createDomMotionComponent)("path"),
    aside: (0, _framerMotion.createDomMotionComponent)("aside"),
    custom: _framerMotion.motion.custom
  };
} else {
  _motion = _framerMotion.motion;
}

var motion = _motion; // re-export everything

exports.motion = motion;