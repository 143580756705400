"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.List = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _List = _interopRequireDefault(require("../List"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var List = (0, _styledComponents.default)(_List.default)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > * {\n   cursor: pointer;\n   \n   &:hover {\n      background-color: ", ";\n   }\n\n  }\n"], ["\n  > * {\n   cursor: pointer;\n   \n   &:hover {\n      background-color: ", ";\n   }\n\n  }\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.colors['light-grey-2'];
});
exports.List = List;
var templateObject_1;