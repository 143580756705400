"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unused = exports.default = void 0;
var _default = {
  unmask: true,
  mask: /^\+?\d*$/
};
exports.default = _default;
var unused = {
  unmask: true,
  mask: [{
    mask: '{+}00 00 00 00 00',
    startsWith: '+47',
    lazy: false,
    country: 'Norway'
  }, {
    mask: '{+}0000000000000',
    startsWith: '+',
    country: 'unknown'
  }, {
    mask: '00000000',
    startsWith: '',
    lazy: false,
    country: 'unknown'
  }],
  dispatch: function dispatch(appended, dynamicMasked, flag) {
    var value = dynamicMasked.value + appended;
    return dynamicMasked.compiledMasks.find(function (m) {
      return value.indexOf(m.startsWith) === 0;
    });
  }
};
exports.unused = unused;