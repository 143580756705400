"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StyledAnchor = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTheming = _interopRequireDefault(require("styled-theming"));

var _primary = _interopRequireDefault(require("./variants/primary"));

var _templateObject;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var variantStyling = _styledTheming.default.variants('mode', 'variant', {
  default: _primary.default,
  primary: _primary.default
});

var StyledAnchor = _styledComponents.default.a(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  text-decoration: underline;\n  cursor: pointer;\n  ", ";\n"])), variantStyling);

exports.StyledAnchor = StyledAnchor;
var _default = StyledAnchor;
exports.default = _default;