"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStyledIcon = exports.createOutlined = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTheming = _interopRequireDefault(require("styled-theming"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var primaryTint = _styledTheming.default.variants('mode', 'color', {
  primary: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors.violet;
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors.violet;
    }
  },
  tertiary: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['light-grey'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['light-grey'];
    }
  },
  success: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['success'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['success'];
    }
  },
  warning: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['rosso60'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['rosso60'];
    }
  }
});

var secondaryTint = _styledTheming.default.variants('mode', 'color', {
  primary: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors.yellow;
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors.yellow;
    }
  },
  tertiary: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['light-grey'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['light-grey'];
    }
  },
  success: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['success'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['success'];
    }
  },
  warning: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['rosso60'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['rosso60'];
    }
  }
});

var tertiaryTint = _styledTheming.default.variants('mode', 'color', {
  primary: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['mint-darken'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['mint-darken'];
    }
  },
  tertiary: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['light-grey'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['light-grey'];
    }
  },
  success: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['success'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['success'];
    }
  },
  warning: {
    light: function light(_a) {
      var theme = _a.theme;
      return theme.variables.colors['rosso60'];
    },
    dark: function dark(_a) {
      var theme = _a.theme;
      return theme.variables.colors['rosso60'];
    }
  }
}); //
// const primaryTint = theme.variants('mode', 'color', {
//   primary: {
//     light: 'var(--bob-core-components-color-violet)',
//     dark: 'var(--bob-core-components-color-violet)',
//   },
//   tertiary: {
//     light: 'var(--bob-core-components-color-light-grey)',
//     dark: 'var(--bob-core-components-color-light-grey)',
//   },
//   success: {
//     light: 'var(--bob-core-components-color-success)',
//     dark: 'var(--bob-core-components-color-success)',
//   },
//   warning: {
//     light: 'var(--bob-core-components-color-rosso60)',
//     dark: 'var(--bob-core-components-color-rosso60)',
//   },
// });
//
// const secondaryTint = theme.variants('mode', 'color', {
//   primary: {
//     light: 'var(--bob-core-components-color-yellow)',
//     dark: 'var(--bob-core-components-color-yellow)',
//   },
//   tertiary: {
//     light: 'var(--bob-core-components-color-light-grey)',
//     dark: 'var(--bob-core-components-color-light-grey)',
//   },
//   success: {
//     light: 'var(--bob-core-components-color-success)',
//     dark: 'var(--bob-core-components-color-success)',
//   },
//   warning: {
//     light: 'var(--bob-core-components-color-rosso60)',
//     dark: 'var(--bob-core-components-color-rosso60)',
//   },
// });
//
// const tertiaryTint = theme.variants('mode', 'color', {
//   primary: {
//     light: 'var(--bob-core-components-color-mint-darken)',
//     dark: 'var(--bob-core-components-color-mint-darken)',
//   },
//   tertiary: {
//     light: 'var(--bob-core-components-color-light-grey)',
//     dark: 'var(--bob-core-components-color-light-grey)',
//   },
//   success: {
//     light: 'var(--bob-core-components-color-success)',
//     dark: 'var(--bob-core-components-color-success)',
//   },
//   warning: {
//     light: 'var(--bob-core-components-color-rosso60)',
//     dark: 'var(--bob-core-components-color-rosso60)',
//   },
// });
// export const sizes = {
//   'xxx-large': '8rem',
//   'xx-large': '7rem',
//   'x-large-1': '4rem',
//   'x-large': '3rem',
//   large: '2rem',
//   medium: '1.5rem',
//   small: '1rem',
//   'x-small': '0.75rem',
//   'xx-small': '0.5rem',
// } as const;


var getSize = function getSize(_a) {
  var _b;

  var size = _a.size,
      theme = _a.theme;
  return size ? (_b = theme === null || theme === void 0 ? void 0 : theme.variables) === null || _b === void 0 ? void 0 : _b.sizes.icon.sizes[size] : '';
};

var getRoundCss = function getRoundCss(_a) {
  var round = _a.round;
  return round ? (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        display: inline-block;\n        border-radius: 50%;\n        background-color: ", ";\n        box-shadow: 0 0 ", " ", ";\n        padding: ", ";\n        margin: ", ";\n        width: calc(", " + ", "); // padding both sides\n        height: calc(", " + ", "); // padding both sides\n      "], ["\n        display: inline-block;\n        border-radius: 50%;\n        background-color: ", ";\n        box-shadow: 0 0 ", " ", ";\n        padding: ", ";\n        margin: ", ";\n        width: calc(", " + ", "); // padding both sides\n        height: calc(", " + ", "); // padding both sides\n      "])), function (_a) {
    var theme = _a.theme;
    return theme.variables.colors.snow;
  }, function (_a) {
    var theme = _a.theme;
    return theme.variables.sizes.padding;
  }, function (_a) {
    var theme = _a.theme;
    return theme.variables.colors['flow-button-shadow'];
  }, function (_a) {
    var theme = _a.theme;
    return theme.variables.sizes.halfPadding;
  }, function (_a) {
    var theme = _a.theme;
    return theme.variables.sizes.halfPadding;
  }, getSize, function (_a) {
    var theme = _a.theme;
    return theme.variables.sizes.padding;
  }, getSize, function (_a) {
    var theme = _a.theme;
    return theme.variables.sizes.padding;
  }) : (0, _styledComponents.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
};

var iconCss = (0, _styledComponents.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  .primarytint {\n    fill: ", ";\n  }\n  .secondarytint {\n    fill: ", ";\n  }\n  .tertiarytint {\n    fill: ", ";\n  }\n  ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n  .primarytint {\n    fill: ", ";\n  }\n  .secondarytint {\n    fill: ", ";\n  }\n  .tertiarytint {\n    fill: ", ";\n  }\n  ", ";\n"])), getSize, getSize, primaryTint, secondaryTint, tertiaryTint, getRoundCss);

var createOutlined = function createOutlined(component) {
  return (0, _styledComponents.default)(component)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .secondarytint,\n  .tertiarytint {\n    display: none;\n  }\n"], ["\n  .secondarytint,\n  .tertiarytint {\n    display: none;\n  }\n"])));
};

exports.createOutlined = createOutlined;

var createStyledIcon = function createStyledIcon(component) {
  return (0, _styledComponents.default)(component)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), iconCss);
};

exports.createStyledIcon = createStyledIcon;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;