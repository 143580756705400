"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  unmask: true,
  mask: [{
    mask: '{+}00 00 00 00 00',
    startsWith: '+47',
    lazy: false,
    country: 'Norway'
  }, {
    mask: '{+}0000000000000',
    startsWith: '+',
    country: 'unknown'
  }, {
    mask: '0000000000000',
    startsWith: '',
    country: 'unknown'
  }, {
    mask: /^\S*@?\S*$/
  }],
  dispatch: function dispatch(appended, dynamicMasked, flag) {
    var value = dynamicMasked.value + appended;
    var string = value.replace(/\d/g, '');
    return dynamicMasked.compiledMasks.find(function (m) {
      if (typeof m.startsWith === 'string' && (!string || string[0] === '+')) {
        return value.indexOf(m.startsWith) === 0;
      }

      if (m.startsWith === undefined && string.length > 0) {
        return m; // Returns emailmask.
      }

      return undefined;
    });
  }
};
exports.default = _default;