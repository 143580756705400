"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fontColors = exports.fontWeightTypes = exports.fontSizes = exports.default = exports.compiledCss = exports.variables = exports.componentsReset = void 0;

var _styledComponents = require("styled-components");

var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var sizes = {
  'xxx-large': '3.75rem',
  'xx-large': '2.25rem',
  'x-large': '1.875rem',
  'large-2': '1.5rem',
  large: '1.125rem',
  'medium-1': '1rem',
  medium: '0.875rem',
  small: '0.75rem',
  'x-small': '0.625rem'
};
var types = ['semibold', 'regular', 'bold', 'light'];
var colors = ['violet', 'violet-darker', 'lighter-grey', 'light-grey', 'coal', 'snow', 'rosso60', 'rosso', 'sonic', 'eggplant', 'mint'];
var componentsReset = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  a {\n    text-decoration: none;\n  }\n  h1,\n  h2,\n  h3,\n  h4,\n  p {\n    margin: 0;\n    padding: 0;\n  }\n"])));
exports.componentsReset = componentsReset;

var getFontWeight = function getFontWeight(type) {
  switch (type) {
    case 'semibold':
      return 'font-weight: 600';

    case 'bold':
      return 'font-weight: 700';

    case 'light':
      return 'font-weight: 300';

    default:
      return 'font-weight: 400';
  }
};

var createTypography = function createTypography(_ref) {
  var type = _ref.type,
      fontSize = _ref.fontSize,
      color = _ref.color;
  return "\n  .bob-core-components-typography__".concat(type, "--").concat(fontSize, "--").concat(color, " {  \n    font-size: ").concat(sizes[fontSize], ";\n    color: var(--bob-core-components-color-").concat(color, ");\n    fill: var(--bob-core-components-color-").concat(color, ");\n    ").concat(getFontWeight(type), ";\n  }\n");
}; // prettier has a problem with formatting the code in the following function, hence the following:
// prettier-ignore


var createTypographyCss = function createTypographyCss(_ref2) {
  var type = _ref2.type,
      fontSize = _ref2.fontSize,
      color = _ref2.color;
  return (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: var(--bob-core-components-color-", ", var(--bob-core-components-color-", ")\n  );\n  ", ";\n"])), sizes[fontSize], color, color, getFontWeight(type));
};

var generate = function generate() {
  var style = "";
  var variables = {}; // let listAllClasses = '';

  types.forEach(function (type) {
    colors.forEach(function (color) {
      Object.keys(sizes).forEach(function (fontSize) {
        // listAllClasses += `\n bob-core-components-typography__${type}--${fontSize}--${color}`;
        style += createTypography({
          type: type,
          color: color,
          fontSize: fontSize
        });
        variables["bob-core-components-typography__".concat(type, "--").concat(fontSize, "--").concat(color)] = createTypographyCss({
          type: type,
          color: color,
          fontSize: fontSize
        });
      });
    });
  });
  return {
    css: (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      ", ";\n    "])), style),
    variables: variables,
    componentsReset: componentsReset
  };
};

var typo = generate();
var variables = typo.variables;
exports.variables = variables;
var compiledCss = typo.css;
exports.compiledCss = compiledCss;
var _default = typo.css;
exports.default = _default;
var fontSizes = sizes;
exports.fontSizes = fontSizes;
var fontWeightTypes = types;
exports.fontWeightTypes = fontWeightTypes;
var fontColors = colors;
/*
* REFERENCE

 bob-core-components-typography__semibold--xxx-large--violet
 bob-core-components-typography__semibold--xx-large--violet
 bob-core-components-typography__semibold--x-large--violet
 bob-core-components-typography__semibold--large--violet
 bob-core-components-typography__semibold--medium-1--violet
 bob-core-components-typography__semibold--medium--violet
 bob-core-components-typography__semibold--small--violet
 bob-core-components-typography__semibold--x-small--violet
 bob-core-components-typography__semibold--xxx-large--lighter-grey
 bob-core-components-typography__semibold--xx-large--lighter-grey
 bob-core-components-typography__semibold--x-large--lighter-grey
 bob-core-components-typography__semibold--large--lighter-grey
 bob-core-components-typography__semibold--medium-1--lighter-grey
 bob-core-components-typography__semibold--medium--lighter-grey
 bob-core-components-typography__semibold--small--lighter-grey
 bob-core-components-typography__semibold--x-small--lighter-grey
 bob-core-components-typography__semibold--xxx-large--light-grey
 bob-core-components-typography__semibold--xx-large--light-grey
 bob-core-components-typography__semibold--x-large--light-grey
 bob-core-components-typography__semibold--large--light-grey
 bob-core-components-typography__semibold--medium-1--light-grey
 bob-core-components-typography__semibold--medium--light-grey
 bob-core-components-typography__semibold--small--light-grey
 bob-core-components-typography__semibold--x-small--light-grey
 bob-core-components-typography__semibold--xxx-large--coal
 bob-core-components-typography__semibold--xx-large--coal
 bob-core-components-typography__semibold--x-large--coal
 bob-core-components-typography__semibold--large--coal
 bob-core-components-typography__semibold--medium-1--coal
 bob-core-components-typography__semibold--medium--coal
 bob-core-components-typography__semibold--small--coal
 bob-core-components-typography__semibold--x-small--coal
 bob-core-components-typography__semibold--xxx-large--snow
 bob-core-components-typography__semibold--xx-large--snow
 bob-core-components-typography__semibold--x-large--snow
 bob-core-components-typography__semibold--large--snow
 bob-core-components-typography__semibold--medium-1--snow
 bob-core-components-typography__semibold--medium--snow
 bob-core-components-typography__semibold--small--snow
 bob-core-components-typography__semibold--x-small--snow
 bob-core-components-typography__semibold--xxx-large--rosso60
 bob-core-components-typography__semibold--xx-large--rosso60
 bob-core-components-typography__semibold--x-large--rosso60
 bob-core-components-typography__semibold--large--rosso60
 bob-core-components-typography__semibold--medium-1--rosso60
 bob-core-components-typography__semibold--medium--rosso60
 bob-core-components-typography__semibold--small--rosso60
 bob-core-components-typography__semibold--x-small--rosso60
 bob-core-components-typography__semibold--xxx-large--rosso
 bob-core-components-typography__semibold--xx-large--rosso
 bob-core-components-typography__semibold--x-large--rosso
 bob-core-components-typography__semibold--large--rosso
 bob-core-components-typography__semibold--medium-1--rosso
 bob-core-components-typography__semibold--medium--rosso
 bob-core-components-typography__semibold--small--rosso
 bob-core-components-typography__semibold--x-small--rosso
 bob-core-components-typography__semibold--xxx-large--sonic
 bob-core-components-typography__semibold--xx-large--sonic
 bob-core-components-typography__semibold--x-large--sonic
 bob-core-components-typography__semibold--large--sonic
 bob-core-components-typography__semibold--medium-1--sonic
 bob-core-components-typography__semibold--medium--sonic
 bob-core-components-typography__semibold--small--sonic
 bob-core-components-typography__semibold--x-small--sonic
 bob-core-components-typography__semibold--xxx-large--eggplant
 bob-core-components-typography__semibold--xx-large--eggplant
 bob-core-components-typography__semibold--x-large--eggplant
 bob-core-components-typography__semibold--large--eggplant
 bob-core-components-typography__semibold--medium-1--eggplant
 bob-core-components-typography__semibold--medium--eggplant
 bob-core-components-typography__semibold--small--eggplant
 bob-core-components-typography__semibold--x-small--eggplant
 bob-core-components-typography__regular--xxx-large--violet
 bob-core-components-typography__regular--xx-large--violet
 bob-core-components-typography__regular--x-large--violet
 bob-core-components-typography__regular--large--violet
 bob-core-components-typography__regular--medium-1--violet
 bob-core-components-typography__regular--medium--violet
 bob-core-components-typography__regular--small--violet
 bob-core-components-typography__regular--x-small--violet
 bob-core-components-typography__regular--xxx-large--lighter-grey
 bob-core-components-typography__regular--xx-large--lighter-grey
 bob-core-components-typography__regular--x-large--lighter-grey
 bob-core-components-typography__regular--large--lighter-grey
 bob-core-components-typography__regular--medium-1--lighter-grey
 bob-core-components-typography__regular--medium--lighter-grey
 bob-core-components-typography__regular--small--lighter-grey
 bob-core-components-typography__regular--x-small--lighter-grey
 bob-core-components-typography__regular--xxx-large--light-grey
 bob-core-components-typography__regular--xx-large--light-grey
 bob-core-components-typography__regular--x-large--light-grey
 bob-core-components-typography__regular--large--light-grey
 bob-core-components-typography__regular--medium-1--light-grey
 bob-core-components-typography__regular--medium--light-grey
 bob-core-components-typography__regular--small--light-grey
 bob-core-components-typography__regular--x-small--light-grey
 bob-core-components-typography__regular--xxx-large--coal
 bob-core-components-typography__regular--xx-large--coal
 bob-core-components-typography__regular--x-large--coal
 bob-core-components-typography__regular--large--coal
 bob-core-components-typography__regular--medium-1--coal
 bob-core-components-typography__regular--medium--coal
 bob-core-components-typography__regular--small--coal
 bob-core-components-typography__regular--x-small--coal
 bob-core-components-typography__regular--xxx-large--snow
 bob-core-components-typography__regular--xx-large--snow
 bob-core-components-typography__regular--x-large--snow
 bob-core-components-typography__regular--large--snow
 bob-core-components-typography__regular--medium-1--snow
 bob-core-components-typography__regular--medium--snow
 bob-core-components-typography__regular--small--snow
 bob-core-components-typography__regular--x-small--snow
 bob-core-components-typography__regular--xxx-large--rosso60
 bob-core-components-typography__regular--xx-large--rosso60
 bob-core-components-typography__regular--x-large--rosso60
 bob-core-components-typography__regular--large--rosso60
 bob-core-components-typography__regular--medium-1--rosso60
 bob-core-components-typography__regular--medium--rosso60
 bob-core-components-typography__regular--small--rosso60
 bob-core-components-typography__regular--x-small--rosso60
 bob-core-components-typography__regular--xxx-large--rosso
 bob-core-components-typography__regular--xx-large--rosso
 bob-core-components-typography__regular--x-large--rosso
 bob-core-components-typography__regular--large--rosso
 bob-core-components-typography__regular--medium-1--rosso
 bob-core-components-typography__regular--medium--rosso
 bob-core-components-typography__regular--small--rosso
 bob-core-components-typography__regular--x-small--rosso
 bob-core-components-typography__regular--xxx-large--sonic
 bob-core-components-typography__regular--xx-large--sonic
 bob-core-components-typography__regular--x-large--sonic
 bob-core-components-typography__regular--large--sonic
 bob-core-components-typography__regular--medium-1--sonic
 bob-core-components-typography__regular--medium--sonic
 bob-core-components-typography__regular--small--sonic
 bob-core-components-typography__regular--x-small--sonic
 bob-core-components-typography__regular--xxx-large--eggplant
 bob-core-components-typography__regular--xx-large--eggplant
 bob-core-components-typography__regular--x-large--eggplant
 bob-core-components-typography__regular--large--eggplant
 bob-core-components-typography__regular--medium-1--eggplant
 bob-core-components-typography__regular--medium--eggplant
 bob-core-components-typography__regular--small--eggplant
 bob-core-components-typography__regular--x-small--eggplant
 bob-core-components-typography__bold--xxx-large--violet
 bob-core-components-typography__bold--xx-large--violet
 bob-core-components-typography__bold--x-large--violet
 bob-core-components-typography__bold--large--violet
 bob-core-components-typography__bold--medium-1--violet
 bob-core-components-typography__bold--medium--violet
 bob-core-components-typography__bold--small--violet
 bob-core-components-typography__bold--x-small--violet
 bob-core-components-typography__bold--xxx-large--lighter-grey
 bob-core-components-typography__bold--xx-large--lighter-grey
 bob-core-components-typography__bold--x-large--lighter-grey
 bob-core-components-typography__bold--large--lighter-grey
 bob-core-components-typography__bold--medium-1--lighter-grey
 bob-core-components-typography__bold--medium--lighter-grey
 bob-core-components-typography__bold--small--lighter-grey
 bob-core-components-typography__bold--x-small--lighter-grey
 bob-core-components-typography__bold--xxx-large--light-grey
 bob-core-components-typography__bold--xx-large--light-grey
 bob-core-components-typography__bold--x-large--light-grey
 bob-core-components-typography__bold--large--light-grey
 bob-core-components-typography__bold--medium-1--light-grey
 bob-core-components-typography__bold--medium--light-grey
 bob-core-components-typography__bold--small--light-grey
 bob-core-components-typography__bold--x-small--light-grey
 bob-core-components-typography__bold--xxx-large--coal
 bob-core-components-typography__bold--xx-large--coal
 bob-core-components-typography__bold--x-large--coal
 bob-core-components-typography__bold--large--coal
 bob-core-components-typography__bold--medium-1--coal
 bob-core-components-typography__bold--medium--coal
 bob-core-components-typography__bold--small--coal
 bob-core-components-typography__bold--x-small--coal
 bob-core-components-typography__bold--xxx-large--snow
 bob-core-components-typography__bold--xx-large--snow
 bob-core-components-typography__bold--x-large--snow
 bob-core-components-typography__bold--large--snow
 bob-core-components-typography__bold--medium-1--snow
 bob-core-components-typography__bold--medium--snow
 bob-core-components-typography__bold--small--snow
 bob-core-components-typography__bold--x-small--snow
 bob-core-components-typography__bold--xxx-large--rosso60
 bob-core-components-typography__bold--xx-large--rosso60
 bob-core-components-typography__bold--x-large--rosso60
 bob-core-components-typography__bold--large--rosso60
 bob-core-components-typography__bold--medium-1--rosso60
 bob-core-components-typography__bold--medium--rosso60
 bob-core-components-typography__bold--small--rosso60
 bob-core-components-typography__bold--x-small--rosso60
 bob-core-components-typography__bold--xxx-large--rosso
 bob-core-components-typography__bold--xx-large--rosso
 bob-core-components-typography__bold--x-large--rosso
 bob-core-components-typography__bold--large--rosso
 bob-core-components-typography__bold--medium-1--rosso
 bob-core-components-typography__bold--medium--rosso
 bob-core-components-typography__bold--small--rosso
 bob-core-components-typography__bold--x-small--rosso
 bob-core-components-typography__bold--xxx-large--sonic
 bob-core-components-typography__bold--xx-large--sonic
 bob-core-components-typography__bold--x-large--sonic
 bob-core-components-typography__bold--large--sonic
 bob-core-components-typography__bold--medium-1--sonic
 bob-core-components-typography__bold--medium--sonic
 bob-core-components-typography__bold--small--sonic
 bob-core-components-typography__bold--x-small--sonic
 bob-core-components-typography__bold--xxx-large--eggplant
 bob-core-components-typography__bold--xx-large--eggplant
 bob-core-components-typography__bold--x-large--eggplant
 bob-core-components-typography__bold--large--eggplant
 bob-core-components-typography__bold--medium-1--eggplant
 bob-core-components-typography__bold--medium--eggplant
 bob-core-components-typography__bold--small--eggplant
 bob-core-components-typography__bold--x-small--eggplant
 bob-core-components-typography__light--xxx-large--violet
 bob-core-components-typography__light--xx-large--violet
 bob-core-components-typography__light--x-large--violet
 bob-core-components-typography__light--large--violet
 bob-core-components-typography__light--medium-1--violet
 bob-core-components-typography__light--medium--violet
 bob-core-components-typography__light--small--violet
 bob-core-components-typography__light--x-small--violet
 bob-core-components-typography__light--xxx-large--lighter-grey
 bob-core-components-typography__light--xx-large--lighter-grey
 bob-core-components-typography__light--x-large--lighter-grey
 bob-core-components-typography__light--large--lighter-grey
 bob-core-components-typography__light--medium-1--lighter-grey
 bob-core-components-typography__light--medium--lighter-grey
 bob-core-components-typography__light--small--lighter-grey
 bob-core-components-typography__light--x-small--lighter-grey
 bob-core-components-typography__light--xxx-large--light-grey
 bob-core-components-typography__light--xx-large--light-grey
 bob-core-components-typography__light--x-large--light-grey
 bob-core-components-typography__light--large--light-grey
 bob-core-components-typography__light--medium-1--light-grey
 bob-core-components-typography__light--medium--light-grey
 bob-core-components-typography__light--small--light-grey
 bob-core-components-typography__light--x-small--light-grey
 bob-core-components-typography__light--xxx-large--coal
 bob-core-components-typography__light--xx-large--coal
 bob-core-components-typography__light--x-large--coal
 bob-core-components-typography__light--large--coal
 bob-core-components-typography__light--medium-1--coal
 bob-core-components-typography__light--medium--coal
 bob-core-components-typography__light--small--coal
 bob-core-components-typography__light--x-small--coal
 bob-core-components-typography__light--xxx-large--snow
 bob-core-components-typography__light--xx-large--snow
 bob-core-components-typography__light--x-large--snow
 bob-core-components-typography__light--large--snow
 bob-core-components-typography__light--medium-1--snow
 bob-core-components-typography__light--medium--snow
 bob-core-components-typography__light--small--snow
 bob-core-components-typography__light--x-small--snow
 bob-core-components-typography__light--xxx-large--rosso60
 bob-core-components-typography__light--xx-large--rosso60
 bob-core-components-typography__light--x-large--rosso60
 bob-core-components-typography__light--large--rosso60
 bob-core-components-typography__light--medium-1--rosso60
 bob-core-components-typography__light--medium--rosso60
 bob-core-components-typography__light--small--rosso60
 bob-core-components-typography__light--x-small--rosso60
 bob-core-components-typography__light--xxx-large--rosso
 bob-core-components-typography__light--xx-large--rosso
 bob-core-components-typography__light--x-large--rosso
 bob-core-components-typography__light--large--rosso
 bob-core-components-typography__light--medium-1--rosso
 bob-core-components-typography__light--medium--rosso
 bob-core-components-typography__light--small--rosso
 bob-core-components-typography__light--x-small--rosso
 bob-core-components-typography__light--xxx-large--sonic
 bob-core-components-typography__light--xx-large--sonic
 bob-core-components-typography__light--x-large--sonic
 bob-core-components-typography__light--large--sonic
 bob-core-components-typography__light--medium-1--sonic
 bob-core-components-typography__light--medium--sonic
 bob-core-components-typography__light--small--sonic
 bob-core-components-typography__light--x-small--sonic
 bob-core-components-typography__light--xxx-large--eggplant
 bob-core-components-typography__light--xx-large--eggplant
 bob-core-components-typography__light--x-large--eggplant
 bob-core-components-typography__light--large--eggplant
 bob-core-components-typography__light--medium-1--eggplant
 bob-core-components-typography__light--medium--eggplant
 bob-core-components-typography__light--small--eggplant
 bob-core-components-typography__light--x-small--eggplant

* */

exports.fontColors = fontColors;