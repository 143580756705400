"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderChildren = exports.getRectLength = exports.getAnimatedRectLength = exports.getBtnSizeByText = void 0;

var _constants = require("./constants");

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

var getBtnSizeByText = function getBtnSizeByText(size) {
  return _constants.BUTTON_SIZES[size] ? _constants.BUTTON_SIZES[size] : _constants.BUTTON_SIZES.large;
};

exports.getBtnSizeByText = getBtnSizeByText;

var getAnimatedRectLength = function getAnimatedRectLength(_ref) {
  var _ref$arrowLength = _ref.arrowLength,
      arrowLength = _ref$arrowLength === void 0 ? _constants.ARROW_LENGTH.SHORT : _ref$arrowLength;
  var shortLength = '1rem';
  var mediumLength = '1.5rem';
  var longLength = '2.5';

  switch (arrowLength) {
    case _constants.ARROW_LENGTH.SHORT:
      return shortLength;

    case _constants.ARROW_LENGTH.MEDIUM:
      return mediumLength;

    case _constants.ARROW_LENGTH.LONG:
      return longLength;

    default:
      return '';
  }
};

exports.getAnimatedRectLength = getAnimatedRectLength;

var getRectLength = function getRectLength(_ref2) {
  var _ref2$arrowLength = _ref2.arrowLength,
      arrowLength = _ref2$arrowLength === void 0 ? _constants.ARROW_LENGTH.SHORT : _ref2$arrowLength;
  var shortLength = '0';
  var mediumLength = '1rem';
  var longLength = '2rem';

  switch (arrowLength) {
    case _constants.ARROW_LENGTH.SHORT:
      return shortLength;

    case _constants.ARROW_LENGTH.MEDIUM:
      return mediumLength;

    case _constants.ARROW_LENGTH.LONG:
      return longLength;

    default:
      return ';';
  }
}; // export const expandingArrow = (variant, loading) =>
//   !loading &&
//   variant !== 'icon' &&
//   variant !== 'quaternary' &&
//   variant !== 'quinary' &&
//   variant !== 'senary' && (
//     <ArrowContainer>
//       <AnimationArrow />
//     </ArrowContainer>
//   );
//
// export const textSpan = (variant, title) => {
//   return (
//     variant !== 'icon' &&
//     title && <TextSpan className={`${NAME}__textspan`}>{title}</TextSpan>
//   );
// };


exports.getRectLength = getRectLength;

var renderChildren = function renderChildren(children) {
  return _typeof(children) === 'object' && children;
}; // export const renderIcon = props =>
//   props.name && (
//     <IconContainer>
//       <Icon {...props} />
//     </IconContainer>
//   );


exports.renderChildren = renderChildren;