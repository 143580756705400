"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTheming = _interopRequireDefault(require("styled-theming"));

var _variants = require("./variants");

var _templateObject;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var variantStyling = _styledTheming.default.variants('mode', 'variant', {
  default: _variants.regular,
  warning: _variants.warning,
  regular: _variants.regular,
  blank: _variants.blank
});

var _default = _styledComponents.default.button.attrs(function (props) {
  return {
    variant: props.variant || 'regular'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: background-color 250ms cubic-bezier(0.78, 0.02, 0.58, 1),\n    box-shadow 250ms cubic-bezier(0.78, 0.02, 0.58, 1);\n  cursor: pointer;\n  outline: none;\n  padding: 0;\n  border: none;\n  width: 100%;\n  height: ", "%;\n  max-width: 1.25rem;\n  ", ";\n  &:active {\n    transform: translateY(0.0625rem);\n  }\n"])), function (_ref) {
  var maxY = _ref.maxY,
      value = _ref.value;
  var val = value / maxY * 100;
  if (val > 0 && val < 1) return 1;
  return val.toFixed();
}, variantStyling);

exports.default = _default;