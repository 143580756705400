"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WarningIcon = exports.Content = exports.StyledA = exports.StyledLi = exports.IconContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _list = require("../list.styles");

var _Icon = _interopRequireDefault(require("../../Icon"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var IconContainer = _styledComponents.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-right: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  svg {\n    height: auto;\n    max-width: ", ";\n  }\n"], ["\n  padding-right: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  svg {\n    height: auto;\n    max-width: ", ";\n  }\n"])), function (_a) {
  var dense = _a.dense,
      theme = _a.theme;
  return dense ? "0" : theme.variables.sizes.padding;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.doublePadding;
});

exports.IconContainer = IconContainer;

var StyledLi = _styledComponents.default.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  &:nth-of-type(even) {\n    background-color: ", ";\n  }\n"], ["\n  ", "\n  &:nth-of-type(even) {\n    background-color: ", ";\n  }\n"])), _list.reset, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.mist20;
});

exports.StyledLi = StyledLi;

var StyledA = _styledComponents.default.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n\n  ", "\n  &:nth-of-type(even) {\n    background-color: ", ";\n  }\n"], ["\n  cursor: pointer;\n\n  ", "\n  &:nth-of-type(even) {\n    background-color: ", ";\n  }\n"])), _list.reset, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.mist20;
});

exports.StyledA = StyledA;

var Content = _styledComponents.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  /* in case if it becomes an anchor*/\n  text-decoration: none;\n  color: inherit;\n  &:hover {\n    color: inherit;\n  }\n  /*end*/\n  padding: ", ";\n  ", "\n"], ["\n  /* in case if it becomes an anchor*/\n  text-decoration: none;\n  color: inherit;\n  &:hover {\n    color: inherit;\n  }\n  /*end*/\n  padding: ", ";\n  ", "\n"])), function (_a) {
  var dense = _a.dense,
      theme = _a.theme;
  return dense ? theme.variables.sizes.halfPadding + " " + theme.variables.sizes.padding : theme.variables.sizes.padding;
}, function (_a) {
  var selected = _a.selected,
      theme = _a.theme;
  return selected && " background-color: " + theme.variables.colors["eggplant10"] + ";\n      box-shadow: inset " + theme.variables.sizes.list.item.shadowOffsetX + " 0rem 0rem " + theme.variables.sizes.list.item.shadowBlur + " " + theme.variables.colors["violet-darker"] + ";\n    ";
});

exports.Content = Content;
var WarningIcon = (0, _styledComponents.default)(_Icon.default).attrs(function () {
  return {
    name: "Warning",
    color: "warning"
  };
})(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
exports.WarningIcon = WarningIcon;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;