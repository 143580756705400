"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LIBRARY_NAME = exports.THEMES = void 0;
var THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
};
exports.THEMES = THEMES;
var LIBRARY_NAME = 'bob-core-components';
exports.LIBRARY_NAME = LIBRARY_NAME;
var _default = THEMES;
exports.default = _default;