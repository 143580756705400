"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var light = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: var(--bob-core-components-color-link-light);\n  &:hover,\n  &:focus {\n    color: var(--bob-core-components-color-mint-darken);\n  }\n  &:active {\n    color: var(--bob-core-components-color-link-light);\n  }\n  &:visited {\n    color: var(--bob-core-components-color-link-light-visited);\n  }\n"])));
var dark = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: var(--bob-core-components-color-link-dark);\n  &:hover,\n  &:focus {\n    color: var(--bob-core-components-color-mint);\n  }\n  &:active {\n    color: var(--bob-core-components-color-link-dark);\n  }\n  &:visited {\n    color: var(--bob-core-components-color-link-dark-visited);\n  }\n"])));
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;