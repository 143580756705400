"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.breakpoints = exports.sizes = exports.progressIndicatorCircleSizes = exports.iconSizes = exports.fontSizes = exports.colors = void 0;

var _variables = _interopRequireDefault(require("./colors/variables"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var __assign = void 0 && (void 0).__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var colors = Object.keys(_variables.default).reduce(function (acc, key) {
  var _a;

  var k = key;
  var newKey = k.split("--bob-core-components-color-")[1];
  return __assign(__assign({}, acc), (_a = {}, _a[newKey] = _variables.default[k], _a));
}, {});
exports.colors = colors;
var fontSizes = {
  "xxx-large": 60,
  "xx-large": 36,
  "x-large": 30,
  "large-2": 24,
  large: 18,
  "medium-1": 16,
  medium: 14,
  defaultSize: 14,
  small: 12,
  "x-small": 10
};
exports.fontSizes = fontSizes;
var onePx = 1;
var twoPx = 2;
var fourPx = 4;
var sixPx = 6;
var eightPx = 8;
var tenPx = 10;
var twelvePx = 12;
var fourteenPx = 14;
var sixteenPx = 16;
var gutterBottom = eightPx;
var padding = sixteenPx;
var halfPadding = padding / 2;
var fourthOfPadding = padding / 4;
var doublePadding = 2 * padding;
var inputHeight = sixteenPx * 3;
var adornmentWidth = sixteenPx * 2 + eightPx;
var iconSizes = {
  'xxx-large': sixteenPx * 8,
  'xx-large': sixteenPx * 7,
  'x-large-1': sixteenPx * 4,
  'x-large': sixteenPx * 3,
  large: sixteenPx * 2,
  medium: sixteenPx * 1.5,
  small: sixteenPx,
  'x-small': sixteenPx * 0.75,
  'xx-small': sixteenPx / 2
};
exports.iconSizes = iconSizes;
var progressIndicatorCircleSizes = {
  'x-small': eightPx,
  small: sixteenPx,
  medium: sixteenPx * 1.5,
  large: sixteenPx * 2,
  'x-large': sixteenPx * 4,
  'xx-large': sixteenPx * 8
};
exports.progressIndicatorCircleSizes = progressIndicatorCircleSizes;
var sizes = {
  adornmentWidth: adornmentWidth,
  fontSizes: fontSizes,
  gutterBottom: gutterBottom,
  onePx: onePx,
  twoPx: twoPx,
  fourPx: fourPx,
  sixPx: sixPx,
  eightPx: eightPx,
  tenPx: tenPx,
  twelvePx: twelvePx,
  fourteenPx: fourteenPx,
  sixteenPx: sixteenPx,
  padding: padding,
  doublePadding: doublePadding,
  halfPadding: halfPadding,
  fourthOfPadding: fourthOfPadding,
  inputHeight: inputHeight,
  accordion: {
    minHeight: sixteenPx * 3,
    buttonAnimation: {
      height: sixteenPx * 2,
      width: sixteenPx * 2
    }
  },
  slider: {
    height: sixteenPx * 1.625,
    buttonTop: sixteenPx * -0.5625
  },
  card: {
    minHeight: sixteenPx * 5,
    imageMaxHeight: sixteenPx * 15
  },
  button: {
    arrowContainerHeight: sixteenPx,
    primary: {
      borderRadius: sixteenPx * 3
    },
    senary: {
      width: sixteenPx * 16
    },
    tertiary: {
      arrowContainerWidth: sixteenPx * 1.6
    }
  },
  progressIndicator: {
    circle: {
      strokeWidth: sixteenPx,
      sizes: progressIndicatorCircleSizes
    }
  },
  icon: {
    sizes: iconSizes
  },
  list: {
    item: {
      shadowBlur: sixteenPx * -2.7,
      shadowOffsetX: sixteenPx * 3
    }
  },
  switch: {
    height: sixteenPx * 1.625,
    width: sixteenPx * 3,
    borderRadius: sixteenPx
  },
  utilityButton: {
    height: sixteenPx * 1.625,
    width: sixteenPx * 1.625,
    top: sixteenPx * -0.5625
  },
  modalDialog: {
    backdropBlur: sixteenPx * 0.3125,
    headerSvgMargin: sixteenPx * 1.5
  }
};
exports.sizes = sizes;
var breakpoints = {
  'xs': 0,
  'sm': 600,
  'md': 960,
  'lg': 1280,
  'xl': 1920
};
exports.breakpoints = breakpoints;

var breakpointUp = function breakpointUp(key) {
  return "@media (min-width:" + breakpoints[key] + "px)";
};

var _default = {
  sizes: sizes,
  colors: colors,
  breakpoints: __assign(__assign({}, breakpoints), {
    up: breakpointUp
  })
};
exports.default = _default;