"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = require("styled-components");

var _constants = require("../constants");

var _helpers = require("../helpers");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var style = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-block;\n  ", " {\n    margin: ", ";\n  }\n  ", " {\n    ", "\n  }\n  ", " {\n    svg {\n      rect {\n        width: ", ";\n      }\n      path {\n        transform: translateX(", ");\n      }\n    }\n  }\n"])), ".".concat(_constants.NAME, "__contentspan"), function (_ref) {
  var theme = _ref.theme;
  return theme.variables.sizes.halfPadding;
}, ".".concat(_constants.NAME, "__textspan"), function (_ref2) {
  var showArrow = _ref2.showArrow;
  return showArrow && (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        text-decoration: underline;\n      "])));
}, ".".concat(_constants.NAME, "__arrow-container"), _helpers.getAnimatedRectLength, _helpers.getAnimatedRectLength);
var dark = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
var light = (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  background-color: transparent;\n"])), style);
var _default = {
  dark: dark,
  light: light
};
exports.default = _default;