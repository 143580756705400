"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalDialogHeader = exports.ChildrenContainer = exports.ModalDialogBackdrop = exports.StyledModalDialog = exports.animate = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _Card = _interopRequireDefault(require("../Card"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var fadeOut = (0, _styledComponents.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    opacity: 1;\n  }\n\n  to {\n    opacity: 0;\n  }\n"], ["\n  from {\n    opacity: 1;\n  }\n\n  to {\n    opacity: 0;\n  }\n"])));
var fadeIn = (0, _styledComponents.keyframes)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n"], ["\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n"])));
var moveIn = (0, _styledComponents.keyframes)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  from {\n    transform: translateY(500%);\n  }\n\n  to {\n    transform: translateY(0);\n  }\n"], ["\n  from {\n    transform: translateY(500%);\n  }\n\n  to {\n    transform: translateY(0);\n  }\n"])));
var moveOut = (0, _styledComponents.keyframes)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  from {\n    transform: translateY();\n  }\n\n  to {\n    transform: translateY(500%);\n  }\n"], ["\n  from {\n    transform: translateY();\n  }\n\n  to {\n    transform: translateY(500%);\n  }\n"])));

var animate = function animate(_a) {
  var animationState = _a.animationState; // if (animationState === 'entered') {
  //   return css` display: flex`;
  // }

  if (animationState === "entering" || animationState === "entered") {
    return (0, _styledComponents.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      display: flex;\n      animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      ", " {\n        animation-delay: 100ms;\n        animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      }\n    "], ["\n      display: flex;\n      animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      ", " {\n        animation-delay: 100ms;\n        animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      }\n    "])), fadeIn, StyledModalDialog, moveIn);
  }

  if (animationState === "exiting") {
    return (0, _styledComponents.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      animation-delay: 100ms;\n      animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      ", " {\n        animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      }\n    "], ["\n      animation-delay: 100ms;\n      animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      ", " {\n        animation: ", " 250ms cubic-bezier(0.78, 0.02, 0.58, 1) forwards;\n      }\n    "])), fadeOut, StyledModalDialog, moveOut);
  }

  return (0, _styledComponents.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: none !important;\n  "], ["\n    display: none !important;\n  "])));
}; // export const StyledModalDialog = styled.div`
//   width: 85%;
//   max-width: 40rem;
//   overflow-y: auto;
//   background-color: var(--bob-core-components-color-mist20);
//   margin: 2rem auto;
//   box-shadow: 0 0 3rem 0.7rem rgba(0, 0, 0, 0.2);
//   border-radius: 0.625rem;
//   padding: 1rem;
// `;


exports.animate = animate;
var StyledModalDialog = (0, _styledComponents.default)(_Card.default)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 85%;\n  max-width: 40rem;\n  max-height: 80vh;\n  & p + p {\n    margin-top: ", ";\n  }\n"], ["\n  width: 85%;\n  max-width: 40rem;\n  max-height: 80vh;\n  & p + p {\n    margin-top: ", ";\n  }\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.padding;
});
exports.StyledModalDialog = StyledModalDialog;

var ModalDialogBackdrop = _styledComponents.default.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  z-index: 100;\n  backdrop-filter: blur(\n    ", "\n  );\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  outline: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n  background: rgba(0, 0, 0, 0.4);\n  ", ";\n"], ["\n  z-index: 100;\n  backdrop-filter: blur(\n    ", "\n  );\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  outline: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n  background: rgba(0, 0, 0, 0.4);\n  ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.modalDialog.backdropBlur;
}, animate);

exports.ModalDialogBackdrop = ModalDialogBackdrop;

var ChildrenContainer = _styledComponents.default.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  display: flex;\n  ", ";\n  ", ";\n  ", ";\n"])), function (_a) {
  var justifyChildren = _a.justifyChildren;
  return justifyChildren && "justify-content: " + justifyChildren;
}, function (_a) {
  var alignChildren = _a.alignChildren;
  return alignChildren && "align-items: " + alignChildren;
}, function (_a) {
  var _b = _a.childrenDirection,
      childrenDirection = _b === void 0 ? "column" : _b;
  return "flex-direction: " + childrenDirection;
});

exports.ChildrenContainer = ChildrenContainer;
var ModalDialogHeader = (0, _styledComponents.default)(_Card.default.Header)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  > svg {\n    margin-left: ", ";\n  }\n"], ["\n  > svg {\n    margin-left: ", ";\n  }\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.modalDialog.headerSvgMargin;
});
exports.ModalDialogHeader = ModalDialogHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;