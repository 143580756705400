"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BUTTON_SIZES = exports.ARROW_LENGTH = exports.ARROW_DIRECTION = exports.ARROW_POSITION = exports.NAME = void 0;

var _constants = require("../constants");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var NAME = "".concat(_constants.LIBRARY_NAME, "__button");
exports.NAME = NAME;
var ARROW_POSITION = {
  LEFT: 'left',
  RIGHT: 'right'
};
exports.ARROW_POSITION = ARROW_POSITION;

var ARROW_DIRECTION = _objectSpread(_objectSpread({}, ARROW_POSITION), {}, {
  UP: 'up',
  DOWN: 'down'
});

exports.ARROW_DIRECTION = ARROW_DIRECTION;
var ARROW_LENGTH = {
  SHORT: 'short',
  MEDIUM: 'medium',
  LONG: 'long'
};
exports.ARROW_LENGTH = ARROW_LENGTH;
var BUTTON_SIZES = {
  medium: '1.7rem',
  large: '3.5rem'
};
exports.BUTTON_SIZES = BUTTON_SIZES;
var _default = NAME;
exports.default = _default;