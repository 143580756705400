"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Circle = exports.Button = exports.ButtonContainer = exports.OverlayButton = exports.Container = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _framerMotion = require("framer-motion");

var _UtilityButton = _interopRequireDefault(require("../UtilityButton"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var Container = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  position: relative;\n  border-radius: ", ";\n  width: ", ";\n  height: ", ";\n"], ["\n  ", ";\n  position: relative;\n  border-radius: ", ";\n  width: ", ";\n  height: ", ";\n"])), function (_a) {
  var disabled = _a.disabled;
  return disabled && (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      opacity: 0.5;\n      pointer-events: none;\n      cursor: default;\n    "], ["\n      opacity: 0.5;\n      pointer-events: none;\n      cursor: default;\n    "])));
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.switch.borderRadius;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.switch.width;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.switch.height;
});
exports.Container = Container;

var OverlayButton = _styledComponents.default.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  opacity: 0;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  opacity: 0;\n  cursor: pointer;\n"])));

exports.OverlayButton = OverlayButton;
var ButtonContainer = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n"])), function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.utilityButton.width;
}, function (_a) {
  var theme = _a.theme;
  return theme.variables.sizes.utilityButton.height;
});
exports.ButtonContainer = ButtonContainer;
var Button = (0, _styledComponents.default)(_UtilityButton.default)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  top: 0;\n"], ["\n  position: relative;\n  top: 0;\n"])));
exports.Button = Button;
var Circle = (0, _styledComponents.default)(_framerMotion.motion.div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"])));
exports.Circle = Circle;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;