"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Container = exports.HiddenCheckbox = exports.StyledCheckbox = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var __makeTemplateObject = void 0 && (void 0).__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var getVariantColor = function getVariantColor(defaultColor, error) {
  return function (_a) {
    var theme = _a.theme,
        variant = _a.variant;
    return theme === null || theme === void 0 ? void 0 : theme.variables.colors[variant === 'error' ? error : defaultColor];
  };
};

var activeColor = getVariantColor('violet80', 'rosso20');
var borderColor = getVariantColor('violet80', 'rosso');
var borderHoverColor = getVariantColor('violet40', 'rosso40');
var checkmark = (0, _styledComponents.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 0.5rem;\n  width: 0.75rem;\n  border-left: 0.125rem solid;\n  border-bottom: 0.125rem solid;\n  transform: rotate(-45deg);\n  left: 0.285rem;\n  top: 0.4375rem;\n"], ["\n  height: 0.5rem;\n  width: 0.75rem;\n  border-left: 0.125rem solid;\n  border-bottom: 0.125rem solid;\n  transform: rotate(-45deg);\n  left: 0.285rem;\n  top: 0.4375rem;\n"])));

var StyledCheckbox = _styledComponents.default.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  padding-left: 1.8rem;\n  padding-top: 0.4rem;\n  cursor: pointer;\n\n  &:before,\n  &:after {\n    position: absolute;\n    content: '';\n    background-color: transparent;\n  }\n\n  &:before {\n    height: 1.313rem;\n    width: 1.313rem;\n    border: solid 0.125rem ", ";\n    left: 0;\n    top: 0.1875rem;\n    border-radius: 0.2rem;\n  }\n\n  &:after {\n    ", ";\n    color: ", ";\n  }\n"], ["\n  position: relative;\n  display: inline-block;\n  padding-left: 1.8rem;\n  padding-top: 0.4rem;\n  cursor: pointer;\n\n  &:before,\n  &:after {\n    position: absolute;\n    content: '';\n    background-color: transparent;\n  }\n\n  &:before {\n    height: 1.313rem;\n    width: 1.313rem;\n    border: solid 0.125rem ", ";\n    left: 0;\n    top: 0.1875rem;\n    border-radius: 0.2rem;\n  }\n\n  &:after {\n    ", ";\n    color: ", ";\n  }\n"])), borderColor, checkmark, function (_a) {
  var theme = _a.theme;
  return theme.variables.colors.snow;
});

exports.StyledCheckbox = StyledCheckbox;

var HiddenCheckbox = _styledComponents.default.input.attrs(function () {
  return {
    type: 'checkbox'
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  opacity: 0;\n  width: 1.5rem;\n  height: 1.5rem;\n  cursor: pointer;\n\n  ", ";\n\n  &:hover,\n  &:focus {\n    & + ", " {\n      &:before {\n        border-color: ", ";\n        background-color: transparent;\n      }\n      &:after {\n        background-color: transparent;\n      }\n    }\n  }\n\n  &:active {\n    & + ", " {\n      &:before {\n      }\n      &:after {\n        color: ", ";\n        background-color: transparent;\n        display: unset;\n      }\n    }\n  }\n  & + ", " {\n    &:after {\n      background-color: transparent;\n      display: none;\n    }\n  }\n  &:checked {\n    & + ", " {\n      &:before {\n        background-color: ", ";\n      }\n      &:after {\n        background-color: transparent;\n        display: unset;\n      }\n    }\n  }\n\n  & + label::after {\n    content: none;\n  }\n\n  &:checked + label::after {\n    content: '';\n  }\n"], ["\n  position: absolute;\n  opacity: 0;\n  width: 1.5rem;\n  height: 1.5rem;\n  cursor: pointer;\n\n  ", ";\n\n  &:hover,\n  &:focus {\n    & + ", " {\n      &:before {\n        border-color: ", ";\n        background-color: transparent;\n      }\n      &:after {\n        background-color: transparent;\n      }\n    }\n  }\n\n  &:active {\n    & + ", " {\n      &:before {\n      }\n      &:after {\n        color: ", ";\n        background-color: transparent;\n        display: unset;\n      }\n    }\n  }\n  & + ", " {\n    &:after {\n      background-color: transparent;\n      display: none;\n    }\n  }\n  &:checked {\n    & + ", " {\n      &:before {\n        background-color: ", ";\n      }\n      &:after {\n        background-color: transparent;\n        display: unset;\n      }\n    }\n  }\n\n  & + label::after {\n    content: none;\n  }\n\n  &:checked + label::after {\n    content: '';\n  }\n"])), function (_a) {
  var disabled = _a.disabled;
  return disabled && 'cursor: default; pointer-events: none;';
}, StyledCheckbox, borderHoverColor, StyledCheckbox, activeColor, StyledCheckbox, StyledCheckbox, activeColor);

exports.HiddenCheckbox = HiddenCheckbox;

var Container = _styledComponents.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  align-items: center;\n\n  ", ";\n"], ["\n  display: flex;\n  position: relative;\n  align-items: center;\n\n  ", ";\n"])), function (_a) {
  var disabled = _a.disabled;
  return disabled && 'opacity: 0.5';
});

exports.Container = Container;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;